import React from 'react';

const Image = (props) => {

    const getImage = () => {
        const images = require.context('../Configurator/Images', true)

        let img
        let color = ''
        let type = ''
        let laterali = ''

        if (props.changeType && props.currentType) {
            let typeSlug 
            
            if (props.currentType==='Motrice') {
                typeSlug = 'motrice'
            }
            else if(props.currentType==='Semirimorchio') {
                typeSlug = 'semirimorchio'
            }
            else {
                console.log(props.currentSubtype)
                if (props.currentSubtype==='motrice') {
                    typeSlug = 'motrice'
                }
                else {
                    typeSlug = 'rimorchio'
                }
            }

            type = '/' + typeSlug
        }

        if (props.changeLaterali && props.currentLaterali) {
            let lateraliSlug

            if (props.currentLaterali==='Francese') {
                lateraliSlug = 'francese'
            }
            else {
                lateraliSlug = 'spondato'
            }

            laterali = '/' + lateraliSlug
        }

        if (props.changeColor && props.currentColor) {
            color = '_' + props.currentColor
        }

        let imgPath = './' + props.directory + type + laterali + '/' + props.name + color + '.png'

        try {
            img = images(imgPath)
        }
        catch (ex) {
            return null //( <div>{imgPath}</div> )
        }

        return <img src={img} alt="" className="img-fluid" />
    }

    return getImage()
}

export default Image;
