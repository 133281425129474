import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import CookieBot from 'react-cookiebot';

import ScrollToTop from './components/ScrollToTop';
import UpdateBodyClass from './components/UpdateBodyClass';
import Home from './components/Home';
import Configurator from './components/Configurator';
import Svgicons from './components/Svgicons';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-173999704-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
    return (
        <div>
            <Router>
                <CookieBot domainGroupId="08f299b9-f343-4d26-b76c-c50657b5f2e1" />
                <ScrollToTop />
                <UpdateBodyClass />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/configurator">
                        <Configurator />
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </Router>

            <Svgicons />
        </div>
    )
}

export default App;
