import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';

class TeliLaterali extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['teli_laterali'];

        this.state = {
            nextStep: 'colore_telo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        if (name==='teli_laterali') {
            value = [value, '']
        }
        else if (name==='spondato') {
            name = 'teli_laterali'
            value = [this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0], value]
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__teli-laterali">
                    <div className="row">
                        <div className="col-22 col-lg-9 offset-1 offset-lg-3">
                            <FormRadio name="teli_laterali" id="teli_laterali1" value="Francese" label="Francese" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0] === 'Francese'} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} />
                        </div>
                        <div className="col-22 col-lg-9 offset-1 offset-lg-0">
                            <FormRadio name="teli_laterali" id="teli_laterali2" value="Spondato" label="Spondato" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0] === 'Spondato'} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} >
                                <div>
                                    <span className="title">Spondato</span>
                                    <p className="description">Quale tipologia?</p>
                                    <FormRadio name="spondato" id="spondato1" value="Fisso" label="Fisso" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1] === 'Fisso'} />
                                    <FormRadio name="spondato" id="spondato2" value="Scorrevole" label="Scorrevole" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1] === 'Scorrevole'} />
                                </div>
                            </FormRadio>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTeliLaterali = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].teli_laterali

    if(!currentStep.teli_laterali[0]) {
        isValid = false
    }

    if(currentStep.teli_laterali[0]==='Spondato' && !currentStep.teli_laterali[1]) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TeliLaterali)
