import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";

const NavItem = (props) => {
    let { url } = useRouteMatch();

    url = url.replace('/riepilogo', '')

    const isEmpty = (element) => {
        if (element) {
            if (typeof element==='string') {
                if (!element) {
                    return true
                }
            }
            else if (typeof element==='object' || element instanceof Array) {
                let emptyCounter = 0
                let elementsCount = 0

                if (typeof element==='object') {
                    elementsCount = Object.keys(element).length
                }
                else {
                    elementsCount = element.length
                }

                for (var key in element) {
                    if (isEmpty(element[key])) {
                        emptyCounter++
                    }
                }

                if (emptyCounter===elementsCount) {
                    return true
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }

        return false
    }

    if(props.currentStep.id === props.steps[props.step].id) {
        if (props.currentType===props.type) {
            return (
                <li className="navigation-nav__item">
                    <strong className="active">{props.steps[props.step].name}</strong>
                </li>
            )
        }
        else {
            if (!isEmpty(props.stepsData[props.steps[props.step].id])) {
                let type = ''
                if (props.type) {
                    type = props.type + '/'
                }
    
                return (
                    <li className="navigation-nav__item">
                        <Link to={`${url}/${type}${props.steps[props.step].slug}`}>
                            <LinkText step={props.steps[props.step]} stepsData={props.stepsData} />
                        </Link>
                    </li>
                )
            }
            else {
                return null
            }

        }
    }
    else if(props.stepsData[props.step]) {
        if (props.steps[props.step].id==='logo') {
            if (isEmpty(props.stepsData.logo.logo)) {
                return null
            }
        }

        if (!isEmpty(props.stepsData[props.steps[props.step].id])) {
            let type = ''
            if (props.type) {
                type = props.type + '/'
            }

            return (
                <li className="navigation-nav__item">
                    <Link to={`${url}/${type}${props.steps[props.step].slug}`}>
                        <LinkText step={props.steps[props.step]} stepsData={props.stepsData} />
                    </Link>
                </li>
            )
        }
        else {
            return null
        }
    }
    else{
        return null
    }
}

const LinkText = (props) => {
    let stepData = props.stepsData[props.step.id]

    if (props.step.id==='misure_mezzo') {
        return (
            <div>
                <span>{props.step.name}:{' '}</span>
                {Object.entries(stepData.misure_mezzo).map((value, i) => {
                    let string = value[1] + 'm'

                    if (i<2) {
                        string += ' x '
                    }

                    return (
                        string
                    )
                })}
            </div>
        )
    }
    else if (props.step.id==='logo') {
        if (stepData.logo==='Aggiungi logo') {
            return (
                <div>
                    <span>{props.step.name}:{' '}</span>
                    Logo caricato
                </div>
            )
        }
        else {
            return (
                <div>
                    <span>{props.step.name}:{' '}</span>
                    {stepData.logo}
                </div>
            )
        }
    }
    else if (props.step.id==='colore_telo') {
        return (
            <div>
                <span>{props.step.name}:{' '}</span>
                {stepData.colore_telo[0]} <span className="color" data-hex={stepData.colore_telo[1]}></span>
            </div>
        )
    }
    else if (props.step.id==='cricchetti') {
        if(stepData.tipologia_cricchetti[0]==='Recupera esistenti') {
            return (
                <div>
                    <span>{props.step.name}:{' '}</span>
                    {stepData.tipologia_cricchetti[0]} 
                    {stepData.tipologia_cricchetti[1]==='Sì' ? ' EN 12641-2 XL' : ''}
                </div>
            )
        }
        else {
            const getColor = () => {
                if (stepData.colore_cricchetti[0]) {
                    return <span>
                        {stepData.colore_cricchetti[0]} <span className="color" data-hex={stepData.colore_cricchetti[1]}></span>
                    </span>
                }
                else {
                    return null
                }
            } 

            return (
                <div>
                    <span>{props.step.name}:{' '}</span>
                    {stepData.numero_cricchetti} x{' '}
                    {stepData.tipologia_cricchetti[0]} 
                    {stepData.tipologia_cricchetti[1]==='Sì' ? ' EN 12641-2 XL' : ''}
                    {getColor()}
                </div>
            )
        }
    }
    else if (props.step.id==='tipologia_cinghie') {
        return (
            <div>
                <span>{props.step.name}:{' '}</span>
                {stepData.numero_cinghie_orizzontali} Cinghie Oriz.
                {stepData.cinghie_verticali_en==='Sì' ? ' / Cinghie Verticali 2300 Kn  EN 12641-2 XL' : ''}
            </div>
        )
    }
    else if (props.step.id==='reflex') {
        const getColor = () => {
            if (stepData.reflex[0]!=='Senza Reflex') {
                return <span className="color" data-hex={stepData.reflex[1]}></span>
            }
            else {
                return null
            }
        } 

        return (
            <div>
                <span>{props.step.name}:{' '}</span>
                {stepData.reflex[0]} {getColor()}
            </div>
        )
    }
    else if (props.step.id==='fronte_tetto') {
        return (
            <div>
                <span>{props.step.name}:{' '}</span>
                {stepData.altezza_frontale}cm
            </div>
        )
    }
    else {
        return (
            <div>
                <span>{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string'){
                        return (
                            value[1] + ' '
                        )
                    }
                    else {
                        let returnStr = ''

                        for (let item in value[1]) {
                            returnStr += value[1][item] + ' '
                        }

                        return returnStr
                    }
                })}
            </div>
        )
    }
}

export default NavItem;
