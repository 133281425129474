import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input.js';
import FormRadioColor from '../Form/RadioColor';

class Cricchetti extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['cricchetti'];

        this.state = {
            nextStep: 'tipologia_cinghie'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        if (name==='tipologia_cricchetti') {
            value = [value, '']
        }

        if (name==='certificazione') {
            name = 'tipologia_cricchetti'
            value = [this.props.stepsData[this.props.currentType][this.step.id][name][0], value]
        }

        if (name==='colore_cricchetti') {
            value = [value, target.getAttribute('data-hex')]
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    showNumber = () => {
        if (this.props.stepsData[this.props.currentType][this.step.id]['tipologia_cricchetti'][0]!=='Recupera esistenti') {
            return 'item-number col-16 col-lg-8 col-xl-5'
        }
        else {
            return 'item-number col-16 col-lg-8 col-xl-5 d-none'
        }
    }

    showColor = () => {
        if (this.props.stepsData[this.props.currentType][this.step.id]['tipologia_cricchetti'][0]==='Cinghia Sicura') {
            return 'item-color col-8 col-lg-8 col-xl-5 offset-lg-2'
        }
        else {
            return 'item-color col-8 col-lg-8 col-xl-5 offset-lg-2 d-none'
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__cricchetti">
                    <div className="scrollable-options">
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti1" value="Recupera esistenti" label="Recupera esistenti" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Recupera esistenti'} />
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti2" value="Cinghia Scatto" label="Cinghia Scatto" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Cinghia Scatto'} />
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti3" value="Cinghia Sicura" label="Cinghia Sicura" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Cinghia Sicura'}>
                            <div>
                                <span className="title">Cinghia Sicura</span>
                                <p className="description">EN 12641-2 XL?</p>
                                <FormRadio name="certificazione" id="certificazione1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Cinghia Sicura' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'Sì'} />
                                <FormRadio name="certificazione" id="certificazione2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Cinghia Sicura' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'No'} />
                            </div>
                        </FormRadio>
                        <FormRadio name="tipologia_cricchetti" id="tipologia_cricchetti4" value="Diretto Scatto" label="Diretto Scatto" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Diretto Scatto'}>
                            <div>
                                <span className="title">Diretto Scatto</span>
                                <p className="description">EN 12641-2 XL?</p>
                                <FormRadio name="certificazione" id="certificazione3" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Diretto Scatto' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'Sì'} />
                                <FormRadio name="certificazione" id="certificazione4" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[0] === 'Diretto Scatto' && this.props.stepsData[this.props.currentType].cricchetti.tipologia_cricchetti[1] === 'No'} />
                            </div>
                        </FormRadio>
                    </div>

                    <div className="row align-items-center justify-content-center bottom-fields">
                        <div  className={this.showNumber()}>
                            <FormInput type="number" name="numero_cricchetti" id="numero_cricchetti" value={this.props.stepsData[this.props.currentType].cricchetti.numero_cricchetti} label="Numero Cricchetti" handleChange={this.handleInputChange} />
                        </div>
                        <div className={this.showColor()}>
                                <div className="row align-items-center">
                                    <div className="col-24 col-lg-auto">
                                        <span className="d-block d-lg-none color-label">Selez. Colore</span>
                                        <span className="d-none d-lg-inline color-label">Colore Cinghia</span>
                                    </div>
                                    <div className="col-24 col-lg-auto">
                                        <FormRadioColor name="colore_cricchetti" id="colore_cricchetti1" value="Nero" label="Nero" hex="#000000" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.colore_cricchetti[0] === 'Nero'} />
                                        <FormRadioColor name="colore_cricchetti" id="colore_cricchetti2" value="Grigio" label="Grigio" hex="#cccccc" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cricchetti.colore_cricchetti[0] === 'Grigio'} />
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="mobile-warning mobile-warning--revert">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-22 offset-1">
                                    <div className="mobile-warning__message">
                                        <svg className="svg-icon svg-icon--rotate-device" viewBox="0 0 210 269" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                            <use xlinkHref="#svg-icon__rotate-device"></use>
                                        </svg>
                                        <span><strong>Perfetto!</strong> Ora puoi rigirare il telefono in verticale e continuare con la configurazione</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateCricchetti = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].cricchetti

    if(!currentStep.tipologia_cricchetti[0]) {
        isValid = false
    }
    else if (currentStep.tipologia_cricchetti[0]!=='Recupera esistenti') {
        if(!currentStep.numero_cricchetti) {
            isValid = false
        }
        else if (currentStep.tipologia_cricchetti[0]==='Cinghia Sicura') {
            if(!currentStep.tipologia_cricchetti[1] || !currentStep.colore_cricchetti[0]) {
                isValid = false
            }
        }
    }

    return isValid;
}

export default withRouter(Cricchetti)
