import React from 'react';

const Input = (props) => {
    return (
        <div className={props.value ? 'form-input form-input--filled' : 'form-input' }>
            <label htmlFor={props.id} className="form-input__label">{props.label}</label>
            <input
                type={props.type}
                className="form-input__control form-control"
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
            />
        </div>
    )
}

export default Input;
