import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input.js';

class TipologiaCarrelli extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tipologia_carrelli'];

        this.state = {
            nextStep: 'riepilogo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
        
        if (this.props.currentType) {
            if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Francese' && this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli==='') {
                this.props.updateData(this.props.currentType, this.step.id, 'numero_carrelli', this.props.stepsData[this.props.currentType].cricchetti.numero_cricchetti)
            }
        }
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = (teli) => {
        let nextStep = 'riepilogo'

        if (teli!=='Teli laterali') {
            nextStep = 'tipologia_tetto'
        }
        else {
            if (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && this.props.currentType==='motrice') {
                nextStep = 'misure_mezzo'
            }
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    showNumber = () => {
        if (this.props.stepsData[this.props.currentType][this.step.id]['tipologia_carrelli']!=='Recupero carrello esistente') {
            return 'item-number col-16 col-lg-8 col-xl-5'
        }
        else {
            return 'item-number col-16 col-lg-8 col-xl-5 d-none'
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep(this.props.stepsData[this.props.currentType].teli.teli)
                    }} />
                    <div className="step step__cricchetti">
                        <div className="scrollable-options">
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli1" value="Recupero carrello esistente" label="Recupero carrello esistente" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Recupero carrello esistente'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli2" value="Versus T.N." label="Versus T.N." image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Versus T.N.'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli3" value="Versus T.V." label="Versus T.V." image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Versus T.V.'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli4" value="EDSCHA 2 ruote su un lato con asola" label="EDSCHA 2 ruote su un lato con asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA 2 ruote su un lato con asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli5" value="EDSCHA 4 ruote staffa piegata" label="EDSCHA 4 ruote staffa piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA 4 ruote staffa piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli6" value="EDSCHA staffa corta" label="EDSCHA staffa corta" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA staffa corta'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli7" value="EDSCHA staffa piegata" label="EDSCHA staffa piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA staffa piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli8" value="EDSCHA Big Star lunga" label="EDSCHA Big Star lunga" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA Big Star lunga'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli9" value="EDSCHA Big Star asola" label="EDSCHA Big Star asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA Big Star asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli10" value="EDSCHA asola" label="EDSCHA asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli11" value="EDSCHA 4 ruote staffa lunga e piegata" label="EDSCHA 4 ruote staffa lunga e piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDSCHA 4 ruote staffa lunga e piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli12" value="Carrellino 4 ruote" label="Carrellino 4 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino 4 ruote'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli13" value="Carrellino 2 ruote" label="Carrellino 2 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino 2 ruote'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli14" value="Carrellino Alu Star staffa lunga" label="Carrellino Alu Star staffa lunga" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino Alu Star staffa lunga'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli15" value="Carrellino Alu Star piccolo" label="Carrellino Alu Star piccolo" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino Alu Star piccolo'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli16" value="Carrellino Alu Schmal" label="Carrellino Alu Schmal" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino Alu Schmal'} />
                        </div>

                        <div className="row align-items-center justify-content-center bottom-fields">
                            <div className={this.showNumber()}>
                                <FormInput type="number" name="numero_carrelli" id="numero_carrelli" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli} label="Numero Carrelli" handleChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTipologiaCarrelli = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].tipologia_carrelli

    if(!currentStep.tipologia_carrelli) {
        isValid = false
    }
    else if (currentStep.tipologia_carrelli!=='Recupero carrello esistente' && !currentStep.numero_carrelli) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TipologiaCarrelli)