import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import Dimensioni from './MisureMezzo/Dimensioni.js';

class MisureMezzo extends React.Component {
    constructor(props) {
        super(props)

        this.step = props.steps['misure_mezzo'];

        this.state = {
            nextStep: 'teli'
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId);
    }

    updateSubstep = (substep) => {
        if (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
            this.props.updateStep(this.step.id, this.step.name, this.props.currentStep.next, null, substep, 2);
        }
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]
            
            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        return(
            <div>
                <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                    if (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                        this.updateSubstep(0)
                    }
                    else {
                        this.updateSubstep(1)
                    }
                }} />
                <ShowMisure type={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentStep={this.props.currentStep} currentType={this.props.currentType} stepsData={this.props.stepsData} handleInputChange={this.handleInputChange} />
            </div>
        )
    }
}

const ShowMisure = (props) => {
    if (!props.type) {
        return null
    }

    if (props.type!=='Motrice + rimorchio') {
        return (
            <div className="step step__misure-mezzo">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={props.currentStep.id} name={props.currentType}/>
                        </div>
                        <div className="step__form">
                            <Dimensioni name="misure_mezzo" values={props.stepsData[props.currentType].misure_mezzo.misure_mezzo} handleInputChange={props.handleInputChange}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if(props.type==='Motrice + rimorchio') {
        if (props.currentStep.substep===0 || !props.currentStep.substep) {
            if (props.currentType==='motrice') {
                return (
                    <div className="step step__misure-mezzo step__misure-mezzo--motrice-rimorchio">
                        <div className="row">
                            <div className="col-22 col-lg-10 offset-1 offset-lg-7">
                                <div className="step__text">
                                    Inizia con la configurazione della <strong>motrice</strong>,<br/> quando avrai finito potrai configurare il rimorchio
                                </div>
                            </div>
                            <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                                <div className="step__image">
                                    <Image directory={props.currentStep.id} name="motrice-rimorchio-1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="step step__misure-mezzo step__misure-mezzo--motrice-rimorchio">
                        <div className="row">
                            <div className="col-22 col-lg-10 offset-1 offset-lg-7">
                                <div className="step__text">
                                    La motrice è completata,<br/> ora inizia la configurazione del <strong>rimorchio</strong>
                                </div>
                            </div>
                            <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                                <div className="step__image">
                                    <Image directory={props.currentStep.id} name="motrice-rimorchio-2"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else if (props.currentStep.substep===1) {
            return (
                <div className="step step__misure-mezzo">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={props.currentStep.id} name={props.currentType}/>
                            </div>
                            <div className="step__form">
                                <Dimensioni name="misure_mezzo" values={props.stepsData[props.currentType].misure_mezzo.misure_mezzo} handleInputChange={props.handleInputChange}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    else {
        return null
    }
}

export const ValidateMisureMezzo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].misure_mezzo.misure_mezzo

    if (stepsData.tipologia_mezzo.tipo_mezzo!=='Motrice + rimorchio') {
        for (let item in currentStep) {
            if (!currentStep[item] || currentStep[item]<=0) {
                isValid = false
            }
        }
    }
    else {
        if (currentStepData.substep===0) {
            isValid = true
        }
        else if (currentStepData.substep===1) {
            for (let item in currentStep) {
                if (!currentStep[item] || currentStep[item]<=0) {
                    isValid = false
                }
            }
        }
    }

    return isValid;
}

export default withRouter(MisureMezzo)
