import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormRadioColor from '../Form/RadioColor';

import ReactTooltip from "react-tooltip";

class ColoreTelo extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['colore_telo'];

        this.state = {
            nextStep: 'logo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        if (name==='colore_telo') {
            value = [value, target.getAttribute('data-hex'), target.getAttribute('data-id')]
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__colore-telo">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory="mezzo" name="mezzo" changeColor={true} currentColor={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[2]} changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} changeLaterali={true} currentLaterali={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]} />
                            </div>
                            <div className="step__form text-center">







                                <span data-tip data-for="tip_colore_telo1">
                                    <ReactTooltip id="tip_colore_telo1" place="top" effect="solid">
                                        Arancio
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo1" value="Arancio" label="Arancio" hex="#ee4214" dataId="1" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Arancio'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo2">
                                    <ReactTooltip id="tip_colore_telo2" place="top" effect="solid">
                                        Argento
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo2" value="Argento" label="Argento" hex="#cdcdcd" dataId="2" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Argento'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo3">
                                    <ReactTooltip id="tip_colore_telo3" place="top" effect="solid">
                                        Azzurro
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo3" value="Azzurro" label="Azzurro" hex="#3885b4" dataId="3" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Azzurro'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo4">
                                    <ReactTooltip id="tip_colore_telo4" place="top" effect="solid">
                                        Bianco
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo4" value="Bianco" label="Bianco" hex="#ffffff" dataId="4" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Bianco'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo5">
                                    <ReactTooltip id="tip_colore_telo5" place="top" effect="solid">
                                        Blu
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo5" value="Blu" label="Blu" hex="#00356b" dataId="5" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Blu'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo6">
                                    <ReactTooltip id="tip_colore_telo6" place="top" effect="solid">
                                        Giallo
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo6" value="Giallo" label="Giallo" hex="#dab500" dataId="6" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Giallo'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo7">
                                    <ReactTooltip id="tip_colore_telo7" place="top" effect="solid">
                                        Grigio Chiaro
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo7" value="Grigio Chiaro" label="Grigio Chiaro" hex="#cdcdcd" dataId="7" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Grigio Chiaro'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo8">
                                    <ReactTooltip id="tip_colore_telo8" place="top" effect="solid">
                                        Grigio Scuro
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo8" value="Grigio Scuro" label="Grigio Scuro" hex="#777a73" dataId="8" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Grigio Scuro'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo9">
                                    <ReactTooltip id="tip_colore_telo9" place="top" effect="solid">
                                        Nero
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo9" value="Nero" label="Nero" hex="#000000" dataId="9" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Nero'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo10">
                                    <ReactTooltip id="tip_colore_telo10" place="top" effect="solid">
                                        Rosso
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo10" value="Rosso" label="Rosso" hex="#bf0000" dataId="10" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Rosso'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo11">
                                    <ReactTooltip id="tip_colore_telo11" place="top" effect="solid">
                                        Verde Chiaro
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo11" value="Verde Chiaro" label="Verde Chiaro" hex="#549e35" dataId="11" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Verde Chiaro'} />
                                </span>

                                <span data-tip data-for="tip_colore_telo12">
                                    <ReactTooltip id="tip_colore_telo12" place="top" effect="solid">
                                        Verde Scuro
                                    </ReactTooltip>
                                    <FormRadioColor name="colore_telo" id="colore_telo12" value="Verde Scuro" label="Verde Scuro" hex="#2e5735" dataId="12" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_telo.colore_telo[0] === 'Verde Scuro'} />
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateColoreTelo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].colore_telo

    if(!currentStep.colore_telo[0] || !currentStep.colore_telo[1]) {
        isValid = false
    }

    return isValid;
}

export default withRouter(ColoreTelo)
