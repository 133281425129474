import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormSelect from '../Form/Select';

class ColoreTetto extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['colore_tetto'];

        this.state = {
            nextStep: 'numero_archi'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = (tetto) => {
        let nextStep = 'archi'

        if (tetto==='Tetto fisso') {
            nextStep = 'traversi_correnti'
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep(this.props.stepsData[this.props.currentType].tipologia_tetto.tipologia_tetto)
                    }} />

                    <div className="step step__colore-tetto">
                        <div className="row justify-content-center">
                            <div className="col-22 col-md-8 col-lg-6">
                                <FormRadio name="colore_tetto" id="colore_tetto1" value="Monocolore bianco" label="Monocolore bianco" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto === 'Monocolore bianco'} />
                            </div>
                            <div className="col-22 col-md-8 col-lg-6 offset-lg-1">
                                <FormRadio name="colore_tetto" id="colore_tetto2" value="Bianco con fascia nera" label="Bianco con fascia nera" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto === 'Bianco con fascia nera'} />
                            </div>
                            <div className="col-22 col-md-8 col-lg-6 offset-lg-1">
                                <FormRadio name="colore_tetto" id="colore_tetto3" value="Fascia Colore" label="Fascia Colore" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto === 'Fascia Colore'}>
                                    <div>
                                        <span className="title">Fascia Colore</span>
                                        <FormSelect type="text" name="colore_tetto_colore" id="colore_tetto_colore" value={this.props.stepsData[this.props.currentType].colore_tetto.colore_tetto_colore} label="Colore" handleChange={this.handleInputChange}>
                                            <option value="">Seleziona</option>
                                            <option value="Arancio">Arancio</option>
                                            <option value="Argento">Argento</option>
                                            <option value="Azzurro">Azzurro</option>
                                            <option value="Bianco">Bianco</option>
                                            <option value="Blu">Blu</option>
                                            <option value="Giallo">Giallo</option>
                                            <option value="Grigio Chiaro">Grigio Chiaro</option>
                                            <option value="Grigio Scuro">Grigio Scuro</option>
                                            <option value="Nero">Nero</option>
                                            <option value="Rosso">Rosso</option>
                                            <option value="Verde Chiaro">Verde Chiaro</option>
                                            <option value="Verde Scuro">Verde Scuro</option>
                                        </FormSelect>
                                    </div>
                                </FormRadio>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateColoreTetto = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].colore_tetto

    if(!currentStep.colore_tetto) {
        isValid = false
    }
    else if(currentStep.colore_tetto==='Fascia Colore' && !currentStep.colore_tetto_colore){
        isValid = false
    }

    return isValid;
}

export default withRouter(ColoreTetto)
