import React from 'react';

import NavItem from './NavItem'

const Nav = (props) => {
    return (
        <ul className="navigation-nav">
            <NavItem key={0} step={'tipologia_mezzo'} steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
            
            {/* <NavTitle type={'Semirimorchio'} truckType={props.stepsData.tipologia_mezzo.tipo_mezzo}/> */}
            {Object.entries(props.steps).map((step, i) => {
                // let maxPosition = 999

                // if (props.steps[props.currentStep.id]) {
                //     maxPosition = props.steps[props.currentStep.id].position
                // }

                //if (step[1].position <= maxPosition) {
                    return (
                        <NavItem key={i} step={step[1].id} type={'semirimorchio'} steps={props.steps} stepsData={props.stepsData.semirimorchio} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                    )
                //}
            })}

            <NavTitle type={'Motrice'} truckType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentType={props.currentType}/>
            {Object.entries(props.steps).map((step, i) => {
                // let maxPosition = 999

                // if (props.steps[props.currentStep.id]) {
                //     maxPosition = props.steps[props.currentStep.id].position
                // }

                //if (step[1].position <= maxPosition) {
                    return (
                        <NavItem key={i} step={step[1].id} type={'motrice'} steps={props.steps} stepsData={props.stepsData.motrice} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                    )
                //}
            })}

            <NavTitle type={'Rimorchio'} truckType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentType={props.currentType} />
            {Object.entries(props.steps).map((step, i) => {
                // let maxPosition = 999

                // if (props.steps[props.currentStep.id]) {
                //     maxPosition = props.steps[props.currentStep.id].position
                // }

                //if (step[1].position <= maxPosition) {
                    return (
                        <NavItem key={i} step={step[1].id} type={'rimorchio'} steps={props.steps} stepsData={props.stepsData.rimorchio} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                    )
                //}
            })}
        </ul>
    )
}

const NavTitle = (props) => {
    if ( (props.truckType==='Semirimorchio' && props.type==='Semirimorchio') || (props.truckType==='Motrice + rimorchio' && props.type==='Motrice') || (props.truckType==='Motrice + rimorchio' && props.type==='Rimorchio' && props.currentType==='rimorchio') ) {
        return (
            <li className="navigation-nav__item navigation-nav__item--title">{props.type}</li>
        )
    }
    else {
        return null
    }
}

export default Nav;
