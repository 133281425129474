import React, { useState } from 'react';

import Nav from './Nav'

const Header = (props) => {

    const [show, setShow] = useState(false);

    const handleShow = () => {
        if(!show) {
            setShow(true)
        }
        else {
            setShow(false)
        }
    };


    let progress = 0
    let stepsCount = Object.keys(props.steps).length + 1

    if (props.currentStep.id) {
        let position = props.steps[props.currentStep.id].position

        if (props.steps[props.currentStep.id].position>3) {
            if (props.stepsData[props.currentType].teli.teli==="Telo tetto") {
                stepsCount = 11
                position = props.steps[props.currentStep.id].position2
            }
            else if (props.stepsData[props.currentType].teli.teli==="Teli laterali") {
                stepsCount = 13
            }
        }

        if (props.steps[props.currentStep.id].position>1) {
            if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                if (props.currentType==='rimorchio') {
                    position = position + stepsCount
                }
                stepsCount = stepsCount * 2
            }
        }

        progress = position / (stepsCount) * 100
        progress = parseFloat(progress.toFixed(4))
    }

    progress = progress + '%'

    const divStyle = {
        width: progress
    }

    return (
        <div className="configurator__header">
            <HeaderTop icon="logo-symbol" steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} handleShow={handleShow} />
            
            <div className="header-progress-bar" style={divStyle}>
                <HeaderTop icon="logo-symbol-monocolor" steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} handleShow={handleShow} />
            </div>

            <div className="navigation">
                <div className={show ? "navigation__content _shown" : "navigation__content"} onClick={handleShow}>
                    <button className="navigation__toggle" onClick={handleShow}>
                        <svg className="svg-icon svg-icon--close" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                            <use xlinkHref="#svg-icon__close"></use>
                        </svg>
                    </button>
                    <span className="navigation-title">Il Tuo Percorso</span>
                    <Nav steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                </div>
            </div>
        </div>
    )
}

const HeaderTop = (props) => {
    return (
        <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
                <div className="col-5 offset-1">
                    <a href="/">
                        <svg className="svg-icon svg-icon--logo-symbol" viewBox="0 0 35 43" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                            <use xlinkHref={"#svg-icon__" + props.icon}></use>
                        </svg>
                    </a>
                </div>
                <div className="col-12">
                    <h1 className="title">{props.currentStep.name}</h1>
                </div>
                <div className="col-6">
                    <div className="navigation">
                        <button className="navigation__toggle" onClick={props.handleShow}>
                            <svg className="svg-icon svg-icon--list" viewBox="0 0 23 15" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                <use xlinkHref="#svg-icon__list"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
