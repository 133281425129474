import React from 'react';

import FormRadio from '../Form/Radio';

class TipologiaMezzo extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tipologia_mezzo'];

        this.state = {
            nextStep: 'misure_mezzo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(false, this.step.id, name, value)
    }

    render() {
        return (
            <div className="step step__tipologia-mezzo">
                <div className="row">
                    <div className="col-22 col-lg-6 offset-1 offset-lg-2">
                        <FormRadio name="tipo_mezzo" id="tipo_mezzo1" value="Semirimorchio" label="Semirimorchio" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_mezzo.tipo_mezzo === 'Semirimorchio'} />
                    </div>
                    <div className="col-22 col-lg-6 offset-1">
                        <FormRadio name="tipo_mezzo" id="tipo_mezzo2" value="Motrice" label="Motrice" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_mezzo.tipo_mezzo === 'Motrice'} />
                    </div>
                    <div className="col-22 col-lg-6 offset-1">
                        <FormRadio name="tipo_mezzo" id="tipo_mezzo3" value="Motrice + rimorchio" label="Motrice + rimorchio" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData.tipologia_mezzo.tipo_mezzo === 'Motrice + rimorchio'} />
                    </div>
                </div>
            </div>
        );
    }
}

export const ValidateTipologiaMezzo = (stepsData, currentStepData) => {
    let isValid = true
    let currentStep = stepsData.tipologia_mezzo.tipo_mezzo

    if(!currentStep) {
        isValid = false
    }

    return isValid;
}

export default TipologiaMezzo;
