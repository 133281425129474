import React, { useState } from 'react';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import itLocale from 'moment/locale/it';
import moment from 'moment';

const inputParsers = {
    date(input) {
        const split = input.split('/');
        const day = split[1]
        const month = split[0];
        const year = split[2];
        return `${year}-${month}-${day}`;
    },
    uppercase(input) {
        return input.toUpperCase();
    },
    number(input) {
        return parseFloat(input);
    },
};

const RichiediPreventivo = (props) => {

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [displayErrors, setDisplayErrors] = useState(false)

    const [date, setDate] = useState(null)
    const [focused, setFocused] = useState(false)
    moment.updateLocale('it', itLocale)

    const submitDisabled = () => {
        if (isLoading) {
            return true
        }
        else {
            return false
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!e.target.checkValidity()) {
            setInvalid(true)
            setDisplayErrors(true)
            return
        }

        setIsLoading(true)

        const form = e.target;
        const data = new FormData(form);

        for (let name of data.keys()) {
            const input = form.elements[name];
            const parserName = input.dataset.parse;
            if (parserName) {
                const parsedValue = inputParsers[parserName](data.get(name))
                data.set(name, parsedValue);
            }
        }

        data.set('type', props.currentType);
        data.set('tipo_mezzo', props.stepsData.tipologia_mezzo.tipo_mezzo);
        
        data.set('semirimorchio_h', props.stepsData.semirimorchio.misure_mezzo.misure_mezzo[0]);
        data.set('semirimorchio_l', props.stepsData.semirimorchio.misure_mezzo.misure_mezzo[1]);
        data.set('semirimorchio_p', props.stepsData.semirimorchio.misure_mezzo.misure_mezzo[2]);
        data.set('semirimorchio_teli', props.stepsData.semirimorchio.teli.teli);
        data.set('semirimorchio_teli_laterali', props.stepsData.semirimorchio.teli_laterali.teli_laterali[0]);
        data.set('semirimorchio_teli_laterali_tipologia_spondato', props.stepsData.semirimorchio.teli_laterali.teli_laterali[1]);
        data.set('semirimorchio_colore_telo', props.stepsData.semirimorchio.colore_telo.colore_telo[0]);
        data.set('semirimorchio_logo', props.stepsData.semirimorchio.logo.file.name_copy ? props.stepsData.semirimorchio.logo.file.name_copy : '');
        data.set('semirimorchio_logo_pos_x', props.stepsData.semirimorchio.logo.file.name_copy ? props.stepsData.semirimorchio.logo.position_percent.x : '');
        data.set('semirimorchio_logo_pos_y', props.stepsData.semirimorchio.logo.file.name_copy ? props.stepsData.semirimorchio.logo.position_percent.y : '');
        data.set('semirimorchio_logo_width', props.stepsData.semirimorchio.logo.file.name_copy ? props.stepsData.semirimorchio.logo.position_percent.width : '');
        data.set('semirimorchio_logo_height', props.stepsData.semirimorchio.logo.file.name_copy ? props.stepsData.semirimorchio.logo.position_percent.height : '');
        data.set('semirimorchio_elastico', props.stepsData.semirimorchio.elastico.elastico);
        data.set('semirimorchio_tipologia_cricchetti', props.stepsData.semirimorchio.cricchetti.tipologia_cricchetti[0]);
        data.set('semirimorchio_tipologia_cricchetti_cinghia_sicura', props.stepsData.semirimorchio.cricchetti.tipologia_cricchetti[1]);
        data.set('semirimorchio_numero_cricchetti', props.stepsData.semirimorchio.cricchetti.numero_cricchetti);
        data.set('semirimorchio_colore_cricchetti', props.stepsData.semirimorchio.cricchetti.colore_cricchetti[0]);
        data.set('semirimorchio_numero_cinghie_orizzontali', props.stepsData.semirimorchio.tipologia_cinghie.numero_cinghie_orizzontali);
        data.set('semirimorchio_cinghie_verticali_en', props.stepsData.semirimorchio.tipologia_cinghie.cinghie_verticali_en);
        data.set('semirimorchio_reflex', props.stepsData.semirimorchio.reflex.reflex[0]);
        data.set('semirimorchio_maniglie', props.stepsData.semirimorchio.maniglie.maniglie);
        data.set('semirimorchio_tipologia_carrelli', props.stepsData.semirimorchio.tipologia_carrelli.tipologia_carrelli);
        data.set('semirimorchio_numero_carrelli', props.stepsData.semirimorchio.tipologia_carrelli.numero_carrelli);
        data.set('semirimorchio_tipologia_tetto', props.stepsData.semirimorchio.tipologia_tetto.tipologia_tetto);
        data.set('semirimorchio_colore_tetto', props.stepsData.semirimorchio.colore_tetto.colore_tetto);
        data.set('semirimorchio_colore_tetto_colore', props.stepsData.semirimorchio.colore_tetto.colore_tetto_colore);
        data.set('semirimorchio_numero_traversi', props.stepsData.semirimorchio.traversi_correnti.numero_traversi);
        data.set('semirimorchio_numero_correnti', props.stepsData.semirimorchio.traversi_correnti.numero_correnti);
        data.set('semirimorchio_numero_archi', props.stepsData.semirimorchio.archi.numero_archi);
        data.set('semirimorchio_file_cinturini', props.stepsData.semirimorchio.cinturini.file_cinturini);
        data.set('semirimorchio_altezza_frontale', props.stepsData.semirimorchio.fronte_tetto.altezza_frontale);
        data.set('semirimorchio_sgocciolatoio', props.stepsData.semirimorchio.sgocciolatoio.sgocciolatoio);

        data.set('motrice_h', props.stepsData.motrice.misure_mezzo.misure_mezzo[0]);
        data.set('motrice_l', props.stepsData.motrice.misure_mezzo.misure_mezzo[1]);
        data.set('motrice_p', props.stepsData.motrice.misure_mezzo.misure_mezzo[2]);
        data.set('motrice_teli', props.stepsData.motrice.teli.teli);
        data.set('motrice_teli_laterali', props.stepsData.motrice.teli_laterali.teli_laterali[0]);
        data.set('motrice_teli_laterali_tipologia_spondato', props.stepsData.motrice.teli_laterali.teli_laterali[1]);
        data.set('motrice_colore_telo', props.stepsData.motrice.colore_telo.colore_telo[0]);
        data.set('motrice_logo', props.stepsData.motrice.logo.file.name_copy ? props.stepsData.motrice.logo.file.name_copy : '');
        data.set('motrice_logo_pos_x', props.stepsData.motrice.logo.file.name_copy ? props.stepsData.motrice.logo.position_percent.x : '');
        data.set('motrice_logo_pos_y', props.stepsData.motrice.logo.file.name_copy ? props.stepsData.motrice.logo.position_percent.y : '');
        data.set('motrice_logo_width', props.stepsData.motrice.logo.file.name_copy ? props.stepsData.motrice.logo.position_percent.width : '');
        data.set('motrice_logo_height', props.stepsData.motrice.logo.file.name_copy ? props.stepsData.motrice.logo.position_percent.height : '');
        data.set('motrice_elastico', props.stepsData.motrice.elastico.elastico);
        data.set('motrice_tipologia_cricchetti', props.stepsData.motrice.cricchetti.tipologia_cricchetti[0]);
        data.set('motrice_tipologia_cricchetti_cinghia_sicura', props.stepsData.motrice.cricchetti.tipologia_cricchetti[1]);
        data.set('motrice_numero_cricchetti', props.stepsData.motrice.cricchetti.numero_cricchetti);
        data.set('motrice_colore_cricchetti', props.stepsData.motrice.cricchetti.colore_cricchetti[0]);
        data.set('motrice_numero_cinghie_orizzontali', props.stepsData.motrice.tipologia_cinghie.numero_cinghie_orizzontali);
        data.set('motrice_cinghie_verticali_en', props.stepsData.motrice.tipologia_cinghie.cinghie_verticali_en);
        data.set('motrice_reflex', props.stepsData.motrice.reflex.reflex[0]);
        data.set('motrice_maniglie', props.stepsData.motrice.maniglie.maniglie);
        data.set('motrice_tipologia_carrelli', props.stepsData.motrice.tipologia_carrelli.tipologia_carrelli);
        data.set('motrice_numero_carrelli', props.stepsData.motrice.tipologia_carrelli.numero_carrelli);
        data.set('motrice_tipologia_tetto', props.stepsData.motrice.tipologia_tetto.tipologia_tetto);
        data.set('motrice_colore_tetto', props.stepsData.motrice.colore_tetto.colore_tetto);
        data.set('motrice_colore_tetto_colore', props.stepsData.motrice.colore_tetto.colore_tetto_colore);
        data.set('motrice_numero_traversi', props.stepsData.motrice.traversi_correnti.numero_traversi);
        data.set('motrice_numero_correnti', props.stepsData.motrice.traversi_correnti.numero_correnti);
        data.set('motrice_numero_archi', props.stepsData.motrice.archi.numero_archi);
        data.set('motrice_file_cinturini', props.stepsData.motrice.cinturini.file_cinturini);
        data.set('motrice_altezza_frontale', props.stepsData.motrice.fronte_tetto.altezza_frontale);
        data.set('motrice_sgocciolatoio', props.stepsData.motrice.sgocciolatoio.sgocciolatoio);

        data.set('rimorchio_h', props.stepsData.rimorchio.misure_mezzo.misure_mezzo[0]);
        data.set('rimorchio_l', props.stepsData.rimorchio.misure_mezzo.misure_mezzo[1]);
        data.set('rimorchio_p', props.stepsData.rimorchio.misure_mezzo.misure_mezzo[2]);
        data.set('rimorchio_teli', props.stepsData.rimorchio.teli.teli);
        data.set('rimorchio_teli_laterali', props.stepsData.rimorchio.teli_laterali.teli_laterali[0]);
        data.set('rimorchio_teli_laterali_tipologia_spondato', props.stepsData.rimorchio.teli_laterali.teli_laterali[1]);
        data.set('rimorchio_colore_telo', props.stepsData.rimorchio.colore_telo.colore_telo[0]);
        data.set('rimorchio_logo', props.stepsData.rimorchio.logo.file.name_copy ? props.stepsData.rimorchio.logo.file.name_copy : '');
        data.set('rimorchio_logo_pos_x', props.stepsData.rimorchio.logo.file.name_copy ? props.stepsData.rimorchio.logo.position_percent.x : '');
        data.set('rimorchio_logo_pos_y', props.stepsData.rimorchio.logo.file.name_copy ? props.stepsData.rimorchio.logo.position_percent.y : '');
        data.set('rimorchio_logo_width', props.stepsData.rimorchio.logo.file.name_copy ? props.stepsData.rimorchio.logo.position_percent.width : '');
        data.set('rimorchio_logo_height', props.stepsData.rimorchio.logo.file.name_copy ? props.stepsData.rimorchio.logo.position_percent.height : '');
        data.set('rimorchio_elastico', props.stepsData.rimorchio.elastico.elastico);
        data.set('rimorchio_tipologia_cricchetti', props.stepsData.rimorchio.cricchetti.tipologia_cricchetti[0]);
        data.set('rimorchio_tipologia_cricchetti_cinghia_sicura', props.stepsData.rimorchio.cricchetti.tipologia_cricchetti[1]);
        data.set('rimorchio_numero_cricchetti', props.stepsData.rimorchio.cricchetti.numero_cricchetti);
        data.set('rimorchio_colore_cricchetti', props.stepsData.rimorchio.cricchetti.colore_cricchetti[0]);
        data.set('rimorchio_numero_cinghie_orizzontali', props.stepsData.rimorchio.tipologia_cinghie.numero_cinghie_orizzontali);
        data.set('rimorchio_cinghie_verticali_en', props.stepsData.rimorchio.tipologia_cinghie.cinghie_verticali_en);
        data.set('rimorchio_reflex', props.stepsData.rimorchio.reflex.reflex[0]);
        data.set('rimorchio_maniglie', props.stepsData.rimorchio.maniglie.maniglie);
        data.set('rimorchio_tipologia_carrelli', props.stepsData.rimorchio.tipologia_carrelli.tipologia_carrelli);
        data.set('rimorchio_numero_carrelli', props.stepsData.rimorchio.tipologia_carrelli.numero_carrelli);
        data.set('rimorchio_tipologia_tetto', props.stepsData.rimorchio.tipologia_tetto.tipologia_tetto);
        data.set('rimorchio_colore_tetto', props.stepsData.rimorchio.colore_tetto.colore_tetto);
        data.set('rimorchio_colore_tetto_colore', props.stepsData.rimorchio.colore_tetto.colore_tetto_colore);
        data.set('rimorchio_numero_traversi', props.stepsData.rimorchio.traversi_correnti.numero_traversi);
        data.set('rimorchio_numero_correnti', props.stepsData.rimorchio.traversi_correnti.numero_correnti);
        data.set('rimorchio_numero_archi', props.stepsData.rimorchio.archi.numero_archi);
        data.set('rimorchio_file_cinturini', props.stepsData.rimorchio.cinturini.file_cinturini);
        data.set('rimorchio_altezza_frontale', props.stepsData.rimorchio.fronte_tetto.altezza_frontale);
        data.set('rimorchio_sgocciolatoio', props.stepsData.rimorchio.sgocciolatoio.sgocciolatoio);
        
        setInvalid(false)
        setDisplayErrors(false)

        // fetch('https://telonitosetto.loc/api/b2c/richiedi-preventivo/', {
        fetch('https://configuratore.telonitosetto.it/api/b2c/richiedi-preventivo/', {
          method: 'POST',
          body: stringifyFormData(data),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status==='ok') {
                        form.reset()
                        setDate(null)
                        setError(false)
                        setSuccess(result.message)
                    }
                    else {
                        setError(result.message)
                        setSuccess(false)
                    }
                    setIsLoading(false)
                },
                (error) => {
                    setError('Si è verificato un errore, riprovare più tardi.')
                    setSuccess(false)
                    setIsLoading(false)
                }
            )
    }

    return (
        <div className="richiedi-preventivo container-fluid">
            <div className="row">
                <div className="col-20 col-lg-10 offset-2 offset-lg-7">
                    <div className="richiedi-preventivo__top">
                        <svg className="svg-icon svg-icon--logo" viewBox="0 0 598 223" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                            <use xlinkHref="#svg-icon__logo"></use>
                        </svg>
                        <p>Inserisci i tuoi dati per richiedere il tuo preventivo</p>
                    </div>

                    <form onSubmit={handleSubmit} noValidate className={displayErrors ? 'richiedi-preventivo__form displayErrors' : 'richiedi-preventivo__form'}>
                        <div className="row justify-content-center">
                        <div className="col-24">
                                <div className="form-group required">
                                    <label htmlFor="company">Azienda</label>
                                    <input type="text" name="company" id="company" className="form-control" required/>
                                </div>
                            </div>
                            <div className="col-24 col-lg-12">
                                <div className="form-group required">
                                    <label htmlFor="name">Nome</label>
                                    <input type="text" name="name" id="name" className="form-control" required/>
                                </div>
                            </div>
                            <div className="col-24 col-lg-12">
                                <div className="form-group required">
                                    <label htmlFor="surname">Cognome</label>
                                    <input type="text" name="surname" id="surname" className="form-control" required/>
                                </div>
                            </div>
                            <div className="col-24 col-lg-12">
                                <div className="form-group required">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="email" id="email" className="form-control" required/>
                                </div>
                            </div>
                            <div className="col-24 col-lg-12">
                                <div className="form-group required">
                                    <label htmlFor="phone">Telefono</label>
                                    <input type="text" name="phone" id="phone" className="form-control" required/>
                                </div>
                            </div>
                            <div className="col-24 col-lg-12">
                                <div className="form-group">
                                    <label htmlFor="date">Data di consegna desiderata</label>
                                    {/* <input type="text" name="date" id="date" data-parse="date" placeholder="__/__/____" pattern="\d{2}\/\d{2}/\d{4}" className="form-control" required/> */}
                                    <SingleDatePicker
                                        date={date}
                                        onDateChange={date => setDate(date)}
                                        focused={focused}
                                        onFocusChange={({ focused }) => setFocused(focused)}
                                        id="date"
                                        placeholder="__/__/____"
                                        required={false}
                                        showDefaultInputIcon={true}
                                        inputIconPosition={"after"}
                                        numberOfMonths={1}
                                        openDirection={"up"}
                                        displayFormat="DD/MM/Y"
                                        noBorder={true}
                                    />
                                </div>
                            </div>
                            <div className="col-24 col-lg-12">
                                <div className="form-group required">
                                    <label htmlFor="sede_montaggio">Sede di montaggio del telo</label>
                                    <select name="sede_montaggio" id="sede_montaggio" className="custom-select" required>
                                        <option value="">Seleziona...</option>
                                        <option value="Cittadella">Cittadella</option>
                                        <option value="Bologna">Bologna</option>
                                        <option value="Livorno">Livorno</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-24 col-lg-18 text-center">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="privacy" id="privacy" required />
                                    <label className="custom-control-label" htmlFor="privacy">Dopo aver preso visione dell'informativa sulla privacy, contattandovi o inviandovi messaggi o richieste autorizzo al trattamento dei miei dati in conformità al GDPR, Regolamento Generale sulla Protezione dei Dati (UE 2016/679). I tuoi dati non saranno ceduti a terzi. <a href="https://telonitosetto.it/privacy-policy/">Informativa Privacy</a></label>
                                </div>
                                <button type="submit" className="btn btn-primary btn-lg" disabled={submitDisabled()}>Invia Richiesta</button>
                                {isLoading && (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>

                    <div className="richiedi-preventivo__response">
                        {invalid && (
                            <div className="alert alert-danger">
                                Si prega di compilare tutti i campi obbligatori.
                            </div>
                        )}
                        {!invalid && error && (
                            <div className="alert alert-danger">
                                {error}
                            </div>
                        )}
                        {!invalid && success && (
                            <div className="alert alert-success">
                                {success}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function stringifyFormData(fd) {
    const data = {};
    for (let key of fd.keys()) {
        data[key] = fd.get(key);
    }
    return JSON.stringify(data, null, 2);
}

export default RichiediPreventivo;
