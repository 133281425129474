import React from 'react';

import FormInput from '../../Form/Input.js';

const Dimensioni = (props) => {
    return (
        <div className="row">
            <div className="col-24 col-lg-8">
                <FormInput type="number" name={`${props.name}[0]`} id="lunghezza" value={props.values[0]} label="Inserisci Lunghezza (m)" handleChange={props.handleInputChange} />
            </div>
            <div className="col-24 col-lg-8">
                <FormInput type="number" name={`${props.name}[1]`} id="larghezza" value={props.values[1]} label="Inserisci Larghezza (m)" handleChange={props.handleInputChange} />
            </div>
            <div className="col-24 col-lg-8">
                <FormInput type="number" name={`${props.name}[2]`} id="altezza" value={props.values[2]} label="Inserisci Altezza (m)" handleChange={props.handleInputChange} />
            </div>
        </div>
    )
}

export default Dimensioni;
