import React from 'react';
import { useRouteMatch, useHistory } from "react-router-dom";

const NextButton = (props) => {
    let { url } = useRouteMatch();
    let history = useHistory();
    let disabled = true;

    if(props.stepsValidation[props.currentStep.id]) {
        disabled = false;
    }

    const handleClick = () => {
        let nextSubStep = props.currentStep.substep + 1

        if (props.currentStep.subSteps===0 || nextSubStep>=props.currentStep.subSteps) {
            if (props.steps[props.currentStep.next]) {
                let type = ''
                
                if (props.steps[props.currentStep.next].typeInSlug) {
                    type = props.currentType + '/'

                    if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                        if (props.currentStep.id==='tipologia_carrelli' && props.currentStep.next==='misure_mezzo' && props.currentType==='motrice') {
                            type = 'rimorchio/'
                        }

                        if (props.currentStep.id==='sgocciolatoio' && props.currentStep.next==='misure_mezzo' && props.currentType==='motrice') {
                            type = 'rimorchio/'
                        }
                    }
                }

                history.push(url + '/' + type + props.steps[props.currentStep.next].slug);
            }
            else {
                history.push(url + '/' + props.currentStep.next);
            }
        }
        else {
            if(props.currentStep.id==='logo' && props.currentStep.substep===0 && props.stepsData[props.currentType].logo.file.size) {
                props.updateStep(props.currentStep.id, props.currentStep.name, props.currentStep.next, null, 3, props.currentStep.subSteps)
            }
            else {
                props.updateStep(props.currentStep.id, props.currentStep.name, props.currentStep.next, null, nextSubStep, props.currentStep.subSteps)
            }
        }
    }

    if (props.currentStep.id!=='logo' || (props.currentStep.id==='logo' && (!props.currentStep.substep || props.currentStep.substep===0 || props.currentStep.substep===3))) {
        return (
            <button disabled={disabled} onClick={handleClick} className="btn btn-primary btn-lg btn-uppercase btn-icon btn-icon__appended btn-next">
                Continua
                <span className="icon">
                    <svg className="svg-icon svg-icon--arrow" viewBox="0 0 20 12" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                        <use xlinkHref="#svg-icon__arrow"></use>
                    </svg>
                </span>
            </button>
        )
    }
    else if(props.currentStep.id==='logo' && props.currentStep.substep===2) {
        return (
            <button disabled={disabled} onClick={handleClick} className="btn btn-secondary btn-lg btn-uppercase btn-icon btn-icon__prepended btn-apply">
                Applica logo
                <span className="icon">
                    <svg className="svg-icon svg-icon--check" viewBox="0 0 11 9" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                        <use xlinkHref="#svg-icon__check"></use>
                    </svg>
                </span>
            </button>
        )
    }
    else {
        return null
    }
}

export default NextButton;
