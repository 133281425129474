import React from 'react'
import { Rnd } from "react-rnd";

import Image from '../Image.js';
import LogoControls from "./Controls"

class Preview extends React.Component {
    constructor(props) {
        super(props);

        this.draggableParent = React.createRef();
        this.draggableImage = React.createRef();
        this.rnd = React.createRef();
    }

    setSizes = (isFirstCall, width, height) => {
        if (this.draggableParent.current) {
            if (width>=this.draggableParent.current.offsetWidth || height>=this.draggableParent.current.offsetHeight) {
                let calculatedSizes = calculateAspectRatioFit(width, height, this.draggableParent.current.offsetWidth, this.draggableParent.current.offsetHeight)

                if (isFirstCall) {
                    let position = {
                        x: (this.draggableParent.current.offsetWidth/2) - (calculatedSizes.width/2),
                        y: (this.draggableParent.current.offsetHeight/2) - (calculatedSizes.height/2)
                    }
                    this.updatePosition(calculatedSizes.width, calculatedSizes.height, position.x, position.y)
                }
                else {
                    this.updatePosition(calculatedSizes.width, calculatedSizes.height, undefined, undefined)
                }
            }
            else {
                if (isFirstCall) {
                    let position = {
                        x: (this.draggableParent.current.offsetWidth/2) - (width/2),
                        y: (this.draggableParent.current.offsetHeight/2) - (height/2)
                    }
                    this.updatePosition(undefined, undefined, position.x, position.y)
                }
            }
        }
    }

    componentDidMount() {
        const image = this.props.stepsData[this.props.currentType].logo.file

        this.setSizes(true, image.width, image.height)

        let resizeTimeout
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout(() => {
                this.setSizes(false, image.width, image.height)
            }, 500)
        })
    }

    updatePosition = (width = this.props.stepsData[this.props.currentType].logo.position.width, height = this.props.stepsData[this.props.currentType].logo.position.height, x = this.props.stepsData[this.props.currentType].logo.position.x, y = this.props.stepsData[this.props.currentType].logo.position.y) => {
        
        const image = this.props.stepsData[this.props.currentType].logo.file

        width = isNaN(width) ? width.replace('px', '') : width
        height = isNaN(height) ? height.replace('px', '') : height
        
        width = !isNaN(width) ? width : image.width
        height = !isNaN(height) ? height : image.height
        x = !isNaN(x) ? x : 0
        y = !isNaN(y) ? y : 0

        let parentWidth = this.draggableParent.current.offsetWidth
        let parentHeight = this.draggableParent.current.offsetHeight

        let percentWidth = (width/parentWidth)*100
        let percentHeight = (height/parentHeight)*100
        let percentX = (x/parentWidth)*100
        let percentY = (y/parentHeight)*100

        this.props.updateData(this.props.currentType, this.props.currentStep.id, undefined, undefined, {
            position: {
                width: width,
                height: height,
                x: x,
                y: y
            },
            position_percent: {
                width: percentWidth,
                height: percentHeight,
                x: percentX,
                y: percentY
            }
        })
    }

    goBack = () => {
        this.props.updateStep(this.props.currentStep.id, this.props.currentStep.name, this.props.currentStep.next, this.props.currentStep.substep - 1, this.props.currentStep.subSteps)
    }

    render () {
        return (
            <div>
                <div className={ "draggable-logo draggable-logo--" + this.props.currentType + '-' + this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0].toLowerCase() } >
                    <div className="draggable-logo__area" ref={this.draggableParent}>
                        <Rnd
                            ref={c => { this.rnd = c; }}
                            size={{ width: this.props.stepsData[this.props.currentType].logo.position.width, height: this.props.stepsData[this.props.currentType].logo.position.height }}
                            position={{ x: this.props.stepsData[this.props.currentType].logo.position.x, y: this.props.stepsData[this.props.currentType].logo.position.y }}
                            bounds={"parent"}
                            lockAspectRatio={"true"}
                            maxWidth={"100%"}
                            maxHeight={"100%"}
                            onDragStop={(e, d) => {
                                this.updatePosition(undefined, undefined, d.x, d.y)
                            }}
                            onResizeStop={(e, direction, ref, delta, position) => {
                                this.updatePosition(ref.style.width, ref.style.height, position.x, position.y)
                            }}
                        >
                            <img src={this.props.stepsData[this.props.currentType].logo.file.preview} alt="" ref={this.draggableImage} />
                        </Rnd>
                    </div>
                    <div className="draggable-logo__background">
                        <Image directory={ 'mezzo/' + this.props.currentType + '/' + this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0].toLowerCase() } name="base-logo-neutra" currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} />
                    </div>
                </div>

                <LogoControls updateStep={this.props.updateStep} currentStep={this.props.currentStep} />

                <div className="mobile-warning">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-22 offset-1">
                                <div className="mobile-warning__message">
                                    <svg className="svg-icon svg-icon--rotate-device" viewBox="0 0 210 269" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                        <use xlinkHref="#svg-icon__rotate-device"></use>
                                    </svg>
                                    <span>Per poter continuare e personalizzare il telo con il tuo logo, ruota il telefono.</span>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}

 /**
  * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
  * images to fit into a certain area.
  *
  * @param {Number} srcWidth width of source image
  * @param {Number} srcHeight height of source image
  * @param {Number} maxWidth maximum available width
  * @param {Number} maxHeight maximum available height
  * @return {Object} { width, height }
  */
 function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
    return { width: srcWidth*ratio, height: srcHeight*ratio }
 }

export default Preview;
