import React, { useState } from 'react';

import { Modal } from 'react-bootstrap'

const PrevButton = (props) => {
    const [show, setShow] = useState(false)

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleReplace = () => {
        props.updateStep(props.currentStep.id, props.currentStep.name, props.currentStep.next, null, 1, props.currentStep.subSteps)
    }

    const handleDelete = () => {
        props.updateStep(props.currentStep.id, props.currentStep.name, props.currentStep.next, null, 0, props.currentStep.subSteps)
    }

    return (
        <div className="logo-controls">
            <button onClick={handleReplace} className="btn btn-link">Sostituisci logo</button>
            <button onClick={handleShow} className="btn btn-link">Elimina logo</button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Sicuro di cancellare<br/> il tuo logo?</Modal.Title>
                </Modal.Header>
        {/*<Modal.Body>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor</Modal.Body>*/}
                <Modal.Footer>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-24 text-center mb-3">
                                <button className="btn btn-secondary btn-lg" onClick={handleDelete}>SÌ CANCELLA</button>
                            </div>
                            <div className="col-24 text-center">
                                <button className="btn btn-link btn-underlined" onClick={handleClose}>Continua con questo logo</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PrevButton;
