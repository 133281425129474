import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class TraversiCorrenti extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['traversi_correnti'];

        this.state = {
            nextStep: 'fronte_tetto'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__traversi-correnti">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="traversi" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-6 offset-1 offset-lg-0">
                            <FormInput type="number" name="numero_traversi" id="numero_traversi" value={this.props.stepsData[this.props.currentType].traversi_correnti.numero_traversi} label="Numero Traversi" handleChange={this.handleInputChange} />
                        </div>
                        <div className="col-22 col-lg-6 offset-1">
                            <FormInput type="number" name="numero_correnti" id="numero_correnti" value={this.props.stepsData[this.props.currentType].traversi_correnti.numero_correnti} label="Numero Correnti" handleChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTraversiCorrenti = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].traversi_correnti

    if(!currentStep.numero_correnti || !currentStep.numero_correnti) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TraversiCorrenti)
