import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const DragDrop = (props) => {
    //
    // Dropzone component
    //

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/jpeg, image/png',
        maxSize: 2000000,
        multiple: false,
        onDragEnter: useCallback((DragEvent) => {
        }, []),
        onDragLeave: useCallback((DragEvent) => {
        }, []),
        onDropRejected: useCallback((files, event) => {
            if (files[0].size>2000000) {
                alert('Limite dimensioni file 2MB')
            }
            if (files[0].type!=='image/jpeg' || files[0].type!=='image/png') {
                alert('Formati file accettati: JPG, PNG')
            }
        }, []),
        onDrop: useCallback(acceptedFiles => {
            let img = document.createElement('img')
            let blob = URL.createObjectURL(acceptedFiles[0])

            img.src = blob;
            img.onload = function() {
                props.handleImageUpload( Object.assign(acceptedFiles[0], {
                    preview: blob,
                    width: img.width,
                    height: img.height,
                    name_copy: acceptedFiles[0].name
                }) )
            }

            img.remove();
        }, [props])
    })


    //
    // Return
    //

    return (
        <div className="form-drag-drop" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="form-drag-drop__content">
                <span className="form-drag-drop__title">Upload Logo</span>
                <p className="form-drag-drop__text">Trascina qui il tuo logo</p>
            </div>
        </div>
    )
}

export default DragDrop;
