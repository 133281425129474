import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Preview from './Logo/Preview';
import Confirm from './Logo/Confirm';
import LogoControls from "./Logo/Controls"

import Image from './Image.js';
import FormRadio from '../Form/Radio';
import FormDragDrop from '../Form/DragDrop';

class Logo extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['logo'];

        this.state = {
            nextStep: 'cricchetti'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)

        if (name==='logo') {
            this.updateSubstep(value, 0)
        }
    }

    handleImageUpload = file => {
        this.props.updateData(this.props.currentType, this.step.id, 'file', file)
        this.updateSubstep('Aggiungi logo', this.props.currentStep.substep + 1)
    }

    updateNextStep = (teli, logo) => {
        let nextStep = 'cricchetti'

        if (teli==='Spondato') {
            nextStep = 'elastico'
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            let substep = null
            let substeps = 0

            if (logo==='Aggiungi logo') {
                substep = 0
                substeps = 4
            }

            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, null, substep, substeps)
        })
    }

    updateSubstep = (choice, substep) => {
        if (choice!=='Aggiungi logo') {
            this.props.updateStep(this.step.id, this.step.name, this.props.currentStep.next, null, null, 0)
        }
        else {
            this.props.updateStep(this.step.id, this.step.name, this.props.currentStep.next, null, substep, 4)
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep(this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0], this.props.stepsData[this.props.currentType].logo.logo)
                    }} />
                    <LogoSteps currentStep={this.props.currentStep} currentType={this.props.currentType} stepsData={this.props.stepsData} handleChange={this.handleInputChange} updateData={this.props.updateData} updateStep={this.props.updateStep} handleImageUpload={this.handleImageUpload} checked={this.props.stepsData[this.props.currentType].logo.logo} />
                </div>
            )
        }
        else {
            return null
        }
    }
}

const LogoSteps = (props) => {
    if (props.currentStep.substep===0 || !props.currentStep.substep) {
        return (
            <div className="step step__logo">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            {/* <Image directory={ props.currentStep.id  + '/' + props.currentType + '/' + props.stepsData[props.currentType].teli_laterali.teli_laterali[0].toLowerCase() } name="base-logo" currentType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={props.currentType} changeColor={true} currentColor={props.stepsData[props.currentType].colore_telo.colore_telo[2]} /> */}
                            <Image directory="mezzo" name="mezzo" changeColor={true} currentColor={props.stepsData[props.currentType].colore_telo.colore_telo[2]} changeType={true} currentType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={props.currentType} stepsData={props.stepsData} changeLaterali={true} currentLaterali={props.stepsData[props.currentType].teli_laterali.teli_laterali[0]} />
                        </div>
                        <div className="step__form">
                            <div className="row">
                                <div className="col-24 col-lg-10 col-xl-8 offset-lg-2 offset-xl-4">
                                    <FormRadio name="logo" id="logo1" value="Aggiungi logo" label="Aggiungi logo" handleChange={props.handleChange} checked={props.checked === 'Aggiungi logo'} />
                                </div>
                                <div className="col-24 col-lg-10 col-xl-8">
                                    <FormRadio name="logo" id="logo2" value="Senza logo" label="Senza logo" handleChange={props.handleChange} checked={props.checked === 'Senza logo'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep===1) {
        return (
            <div className="step step__logo">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            {/* <Image directory={ props.currentStep.id  + '/' + props.currentType + '/' + props.stepsData[props.currentType].teli_laterali.teli_laterali[0].toLowerCase() } name="base-logo" currentType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={props.currentType} changeColor={true} currentColor={props.stepsData[props.currentType].colore_telo.colore_telo[2]} /> */}
                            <Image directory="mezzo" name="mezzo" changeColor={true} currentColor={props.stepsData[props.currentType].colore_telo.colore_telo[2]} changeType={true} currentType={props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={props.currentType} stepsData={props.stepsData} changeLaterali={true} currentLaterali={props.stepsData[props.currentType].teli_laterali.teli_laterali[0]} />
                        </div>
                    </div>
                    <div className="col-22 col-lg-10 col-xl-8 offset-1 offset-lg-7 offset-xl-8">
                        <div className="step__form">
                            <FormDragDrop handleImageUpload={props.handleImageUpload} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep===2) {
        return (
            <div className="step step__logo">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <Preview currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} updateData={props.updateData} updateStep={props.updateStep} />
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep===3) {
        return (
            <div className="step step__logo">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <Confirm currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} updateData={props.updateData} updateStep={props.updateStep} />
                        <LogoControls updateStep={props.updateStep} currentStep={props.currentStep} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

export const ValidateLogo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].logo

    if (!currentStep.logo) {
        isValid = false
    }
    else {
        if (currentStep.logo!=='Senza logo') {
            if (currentStepData.substep===0) {
                isValid = true
            }
            else if (currentStepData.substep===1) {
                if (!currentStep.file.size) {
                    isValid = false
                }
            }
            else if (currentStepData.substep===2) {
                isValid = true
            }
        }
    }

    return isValid;
}

export default withRouter(Logo)
