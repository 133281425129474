import React from 'react';
import { Switch, Route, useParams, Redirect, withRouter } from "react-router-dom";
import SimpleStorage from "react-simple-storage";

import Header from './Configurator/Header';
import PrevButton from './Configurator/PrevButton';
import NextButton from './Configurator/NextButton';

import TipologiaMezzo, { ValidateTipologiaMezzo } from './Configurator/TipologiaMezzo';
import MisureMezzo, { ValidateMisureMezzo } from './Configurator/MisureMezzo';
import Teli, { ValidateTeli } from './Configurator/Teli';
import TeliLaterali, { ValidateTeliLaterali } from './Configurator/TeliLaterali';
import ColoreTelo, { ValidateColoreTelo } from './Configurator/ColoreTelo';
import Logo, { ValidateLogo } from './Configurator/Logo';
import Elastico, { ValidateElastico } from './Configurator/Elastico';
import Cricchetti, { ValidateCricchetti } from './Configurator/Cricchetti';
import TipologiaCinghie, { ValidateTipologiaCinghie } from './Configurator/TipologiaCinghie';
import Reflex, { ValidateReflex } from './Configurator/Reflex';
import Maniglie, { ValidateManiglie } from './Configurator/Maniglie';
import TipologiaCarrelli, { ValidateTipologiaCarrelli } from './Configurator/TipologiaCarrelli';
import TipologiaTetto, { ValidateTipologiaTetto } from './Configurator/TipologiaTetto';
import ColoreTetto, { ValidateColoreTetto } from './Configurator/ColoreTetto';
import TraversiCorrenti, { ValidateTraversiCorrenti } from './Configurator/TraversiCorrenti';
import Archi, { ValidateArchi } from './Configurator/Archi';
import Cinturini, { ValidateCinturini } from './Configurator/Cinturini';
import FronteTetto, { ValidateFronteTetto } from './Configurator/FronteTetto';
import Sgocciolatoio, { ValidateSgocciolatoio } from './Configurator/Sgocciolatoio';

import Riepilogo from './Configurator/Riepilogo';
import RichiediPreventivo from './Configurator/RichiediPreventivo';

class Configurator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            steps: {
                tipologia_mezzo: {
                    id: 'tipologia_mezzo',
                    name: 'Seleziona la tipologia di mezzo',
                    slug: 'tipologia-mezzo',
                    typeInSlug: false,
                    position: 1
                },
                misure_mezzo: {
                    id: 'misure_mezzo',
                    name: 'Inserisci le misure',
                    slug: 'misure-mezzo',
                    typeInSlug: true,
                    position: 2
                },
                teli: {
                    id: 'teli',
                    name: 'Scelta teli',
                    slug: 'teli',
                    typeInSlug: true,
                    position: 3
                },
                teli_laterali: {
                    id: 'teli_laterali',
                    name: 'Scelta teli laterali',
                    slug: 'teli-laterali',
                    typeInSlug: true,
                    position: 4
                },
                colore_telo: {
                    id: 'colore_telo',
                    name: 'Colore telo',
                    slug: 'colore-telo',
                    typeInSlug: true,
                    position: 5
                },
                logo: {
                    id: 'logo',
                    name: 'Aggiungi il tuo logo',
                    slug: 'logo',
                    typeInSlug: true,
                    position: 6
                },
                elastico: {
                    id: 'elastico',
                    name: 'Elastico',
                    slug: 'elastico',
                    typeInSlug: true,
                    position: 7
                },
                cricchetti: {
                    id: 'cricchetti',
                    name: 'Tipologia Cricchetti',
                    slug: 'cricchetti',
                    typeInSlug: true,
                    position: 8
                },
                tipologia_cinghie: {
                    id: 'tipologia_cinghie',
                    name: 'Tipologia Cinghie',
                    slug: 'tipologia-cinghie',
                    typeInSlug: true,
                    position: 9
                },
                reflex: {
                    id: 'reflex',
                    name: 'Reflex',
                    slug: 'reflex',
                    typeInSlug: true,
                    position: 10
                },
                maniglie: {
                    id: 'maniglie',
                    name: 'Maniglie',
                    slug: 'maniglie',
                    typeInSlug: true,
                    position: 11
                },
                tipologia_carrelli: {
                    id: 'tipologia_carrelli',
                    name: 'Tipologia Carrelli',
                    slug: 'tipologia-carrelli',
                    typeInSlug: true,
                    position: 12
                },
                tipologia_tetto: {
                    id: 'tipologia_tetto',
                    name: 'Tipologia Tetto',
                    slug: 'tipologia-tetto',
                    typeInSlug: true,
                    position: 13,
                    position2: 4
                },
                colore_tetto: {
                    id: 'colore_tetto',
                    name: 'Colore Tetto/Spalla',
                    slug: 'colore-tetto',
                    typeInSlug: true,
                    position: 14,
                    position2: 5
                },
                traversi_correnti: {
                    id: 'traversi_correnti',
                    name: 'Traversi e Correnti',
                    slug: 'traversi-correnti',
                    typeInSlug: true,
                    position: 15,
                    position2: 6
                },
                archi: {
                    id: 'archi',
                    name: 'Archi',
                    slug: 'archi',
                    typeInSlug: true,
                    position: 16,
                    position2: 7
                },
                cinturini: {
                    id: 'cinturini',
                    name: 'File Cinturini',
                    slug: 'cinturini',
                    typeInSlug: true,
                    position: 17,
                    position2: 8
                },
                fronte_tetto: {
                    id: 'fronte_tetto',
                    name: 'Fronte Tetto',
                    slug: 'fronte-tetto',
                    typeInSlug: true,
                    position: 18,
                    position2: 9
                },
                sgocciolatoio: {
                    id: 'sgocciolatoio',
                    name: 'Sgocciolatoio',
                    slug: 'sgocciolatoio',
                    typeInSlug: true,
                    position: 19,
                    position2: 10
                }
            },
            stepsData: this._initialStepsData(),
            currentStep: {
                id: '',
                name: '',
                prev: '',
                next: '',
                substep: null,
                subSteps: 0
            },
            currentType: '',
            stepsValidation: {}
        };
    }

    _initialStepsData = () => {
        return {
            tipologia_mezzo: {
                tipo_mezzo: ''
            },
            semirimorchio: {
                misure_mezzo: {
                    misure_mezzo: ['', '', ''],
                },
                teli: {
                    teli: ''
                },
                teli_laterali: {
                    teli_laterali: ['', ''],
                },
                colore_telo: {
                    colore_telo: ['', '', '']
                },
                logo: {
                    logo: '',
                    file: {},
                    position: {
                        x: 0,
                        y: 0,
                        width: 'auto',
                        height: 'auto'
                    },
                    position_percent: {
                        x: 0,
                        y: 0,
                        width: 'auto',
                        height: 'auto'
                    }
                },
                elastico: {
                    elastico: ''
                },
                cricchetti: {
                    tipologia_cricchetti: ['', ''],
                    numero_cricchetti: '',
                    colore_cricchetti: ['', '']
                },
                tipologia_cinghie: {
                    numero_cinghie_orizzontali: '',
                    cinghie_verticali_en: ''
                },
                reflex: {
                    reflex: ['', '']
                },
                maniglie: {
                    maniglie: ''
                },
                tipologia_carrelli: {
                    tipologia_carrelli: '',
                    numero_carrelli: ''
                },
                tipologia_tetto: {
                    tipologia_tetto: ''
                },
                colore_tetto: {
                    colore_tetto: '',
                    colore_tetto_colore: '',
                },
                traversi_correnti: {
                    numero_traversi: '',
                    numero_correnti: ''
                },
                archi: {
                    numero_archi: ''
                },
                cinturini: {
                    file_cinturini: ''
                },
                fronte_tetto: {
                    altezza_frontale: ''
                },
                sgocciolatoio: {
                    sgocciolatoio: ''
                }
            },
            motrice: {
                misure_mezzo: {
                    misure_mezzo: ['', '', ''],
                },
                teli: {
                    teli: ''
                },
                teli_laterali: {
                    teli_laterali: ['', ''],
                },
                colore_telo: {
                    colore_telo: ['', '', '']
                },
                logo: {
                    logo: '',
                    file: {},
                    position: {
                        x: 0,
                        y: 0,
                        width: 'auto',
                        height: 'auto'
                    },
                    position_percent: {
                        x: 0,
                        y: 0,
                        width: 'auto',
                        height: 'auto'
                    }
                },
                elastico: {
                    elastico: ''
                },
                cricchetti: {
                    tipologia_cricchetti: ['', ''],
                    numero_cricchetti: '',
                    colore_cricchetti: ['', '']
                },
                tipologia_cinghie: {
                    numero_cinghie_orizzontali: '',
                    cinghie_verticali_en: ''
                },
                reflex: {
                    reflex: ['', '']
                },
                maniglie: {
                    maniglie: ''
                },
                tipologia_carrelli: {
                    tipologia_carrelli: '',
                    numero_carrelli: ''
                },
                tipologia_tetto: {
                    tipologia_tetto: ''
                },
                colore_tetto: {
                    colore_tetto: '',
                    colore_tetto_colore: '',
                },
                traversi_correnti: {
                    numero_traversi: '',
                    numero_correnti: ''
                },
                archi: {
                    numero_archi: ''
                },
                cinturini: {
                    file_cinturini: ''
                },
                fronte_tetto: {
                    altezza_frontale: ''
                },
                sgocciolatoio: {
                    sgocciolatoio: ''
                }
            },
            rimorchio: {
                misure_mezzo: {
                    misure_mezzo: ['', '', ''],
                },
                teli: {
                    teli: ''
                },
                teli_laterali: {
                    teli_laterali: ['', ''],
                },
                colore_telo: {
                    colore_telo: ['', '', '']
                },
                logo: {
                    logo: '',
                    file: {},
                    position: {
                        x: 0,
                        y: 0,
                        width: 'auto',
                        height: 'auto'
                    },
                    position_percent: {
                        x: 0,
                        y: 0,
                        width: 'auto',
                        height: 'auto'
                    }
                },
                elastico: {
                    elastico: ''
                },
                cricchetti: {
                    tipologia_cricchetti: ['', ''],
                    numero_cricchetti: '',
                    colore_cricchetti: ['', '']
                },
                tipologia_cinghie: {
                    numero_cinghie_orizzontali: '',
                    cinghie_verticali_en: ''
                },
                reflex: {
                    reflex: ['', '']
                },
                maniglie: {
                    maniglie: ''
                },
                tipologia_carrelli: {
                    tipologia_carrelli: '',
                    numero_carrelli: ''
                },
                tipologia_tetto: {
                    tipologia_tetto: ''
                },
                colore_tetto: {
                    colore_tetto: '',
                    colore_tetto_colore: '',
                },
                traversi_correnti: {
                    numero_traversi: '',
                    numero_correnti: ''
                },
                archi: {
                    numero_archi: ''
                },
                cinturini: {
                    file_cinturini: ''
                },
                fronte_tetto: {
                    altezza_frontale: ''
                },
                sgocciolatoio: {
                    sgocciolatoio: ''
                }
            }
        }
    }

    updateStep = (stepId, stepName, nextStep, type = null, substep = null, subSteps = 0) => {
        let prevStep = this.state.currentStep.id !== stepId ? this.state.currentStep.id : null

        if (!type) {
            type = this.state.currentType
        }

        this.setState({
            currentStep: {
                id: stepId,
                name: stepName,
                prev: prevStep,
                next: nextStep,
                substep: substep,
                subSteps: subSteps
            },
            currentType: type
        }, () => {
            this.validateStep(stepId)
        })
    };

    updateData = (type, step, name = null, value = null, object = null) => {
        if (name) {
            if (name.indexOf('[')>0) {
                let newName = name.replace(/\[(\w+)]/g, '')
                let newValue = this.state.stepsData[step][newName]
    
                newValue[/\[(.*?)\]/g.exec(name)[1]] = value
    
                name = newName
                value = newValue
            }

            if (type) {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [type]: {
                            ...this.state.stepsData[type],
                            [step]:
                            {
                                ...this.state.stepsData[type][step],
                                [name]: value
                            }
                        }
                    }
                }, () => {
                    this.validateStep(step)
                })
            }
            else {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [step]:
                            {
                                ...this.state.stepsData[step],
                                [name]: value
                            }
                    }
                }, () => {
                    this.validateStep(step)
                })
            }
        }
        else if (object) {
            if (type) {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [type]: {
                            ...this.state.stepsData[type],
                            [step]:
                            {
                                ...this.state.stepsData[type][step],
                                ...object
                            }
                        }
                    }
                }, () => {
                    this.validateStep(step)
                })
            }
            else {
                this.setState({
                    stepsData:
                    {
                        ...this.state.stepsData,
                        [step]:
                        {
                            ...this.state.stepsData[step],
                            ...object
                        }
                    }
                }, () => {
                    this.validateStep(step)
                })
            }
        }
    };

    validateStep = (step) => {
        if (step==='tipologia_mezzo') {
            let validation = ValidateTipologiaMezzo(this.state.stepsData, this.state.currentStep)
            let truckType = ''

            if (validation) {    
                if (this.state.stepsData.tipologia_mezzo.tipo_mezzo==='Semirimorchio') {
                    truckType = 'semirimorchio'
                }
                else if (this.state.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice' || this.state.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                    truckType = 'motrice'
                }

                if (truckType!==this.state.currentType) {
                    this.setState({
                        stepsData: {
                            ...this.state.stepsData,
                            semirimorchio: {
                                ...this._initialStepsData().semirimorchio,
                                misure_mezzo: {
                                    misure_mezzo: ['', '', ''],
                                }
                            },
                            motrice: {
                                ...this._initialStepsData().motrice,
                                misure_mezzo: {
                                    misure_mezzo: ['', '', ''],
                                }
                            },
                            rimorchio: {
                                ...this._initialStepsData().rimorchio,
                                misure_mezzo: {
                                    misure_mezzo: ['', '', ''],
                                }
                            }
                        }
                    })
                }
            }

            this.updateValidation(step, validation, truckType)
        }
        else if (step==='misure_mezzo') {
            this.updateValidation(step, ValidateMisureMezzo(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='teli') {
            let validation = ValidateTeli(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation && this.state.stepsData[this.state.currentType]) {
                if (this.state.stepsData[this.state.currentType].teli.teli==='Telo tetto') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                teli_laterali: this._initialStepsData()[this.state.currentType].teli_laterali,
                                colore_telo: this._initialStepsData()[this.state.currentType].colore_telo,
                                logo: this._initialStepsData()[this.state.currentType].logo,
                                elastico: this._initialStepsData()[this.state.currentType].elastico,
                                cricchetti: this._initialStepsData()[this.state.currentType].cricchetti,
                                tipologia_cinghie: this._initialStepsData()[this.state.currentType].tipologia_cinghie,
                                reflex: this._initialStepsData()[this.state.currentType].reflex,
                                maniglie: this._initialStepsData()[this.state.currentType].maniglie,
                                tipologia_carrelli: this._initialStepsData()[this.state.currentType].tipologia_carrelli
                            }
                        }
                    })
                }
                else if(this.state.stepsData[this.state.currentType].teli.teli==='Teli laterali') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                tipologia_tetto: this._initialStepsData()[this.state.currentType].tipologia_tetto,
                                colore_tetto: this._initialStepsData()[this.state.currentType].colore_tetto,
                                traversi_correnti: this._initialStepsData()[this.state.currentType].traversi_correnti,
                                archi: this._initialStepsData()[this.state.currentType].archi,
                                cinturini: this._initialStepsData()[this.state.currentType].cinturini,
                                fronte_tetto: this._initialStepsData()[this.state.currentType].fronte_tetto,
                                sgocciolatoio: this._initialStepsData()[this.state.currentType].sgocciolatoio
                            }
                        }
                    })
                }
            }
        }
        else if (step==='teli_laterali') {
            let validation = ValidateTeliLaterali(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].teli_laterali.teli_laterali[0]==='Francese') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                elastico: this._initialStepsData()[this.state.currentType].elastico
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                cricchetti: this._initialStepsData()[this.state.currentType].cricchetti,
                                tipologia_cinghie: this._initialStepsData()[this.state.currentType].tipologia_cinghie,
                                reflex: this._initialStepsData()[this.state.currentType].reflex,
                                maniglie: this._initialStepsData()[this.state.currentType].maniglie
                            }
                        }
                    })
                }
            }
        }
        else if (step==='colore_telo') {
            this.updateValidation(step, ValidateColoreTelo(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='logo') {
            let validation = ValidateLogo(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.currentStep.substep===0) {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                logo: {
                                    ...this.state.stepsData[this.state.currentType].logo,
                                    file: this._initialStepsData()[this.state.currentType].logo.file,
                                    position: this._initialStepsData()[this.state.currentType].logo.position,
                                    position_percent: this._initialStepsData()[this.state.currentType].logo.position_percent
                                }
                            }
                        }
                    })
                }
                if (this.state.currentStep.substep===1) {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                logo: {
                                    ...this.state.stepsData[this.state.currentType].logo,
                                    position: this._initialStepsData()[this.state.currentType].logo.position,
                                    position_percent: this._initialStepsData()[this.state.currentType].logo.position_percent
                                }
                            }
                        }
                    })
                }
                if (this.state.currentStep.substep===3) {
                    const data = new FormData();
                    data.set('logo', this.state.stepsData[this.state.currentType].logo.file)

                    fetch('https://configuratore.telonitosetto.it/api/b2c/upload-logo/' + this.state.currentType + '/', {
                        method: 'POST',
                        body: data,
                    })
                        .then(res => res.json())
                        .then(
                            (result) => {
                                if (result.status==='ok') {
                                    console.log('logo upload ok')
                                }
                                else {
                                    console.log('logo upload error')
                                }
                            },
                            (error) => {
                                console.log('logo upload error')
                            }
                        )
                }
            }
        }
        else if (step==='elastico') {
            this.updateValidation(step, ValidateElastico(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='cricchetti') {
            let validation = ValidateCricchetti(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].cricchetti.tipologia_cricchetti[0]==='Recupera esistenti') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                cricchetti: {
                                    ...this.state.stepsData[this.state.currentType].cricchetti,
                                    numero_cricchetti: '',
                                    colore_cricchetti: ['', '']
                                }
                            }
                        }
                    })
                }
                else if(this.state.stepsData[this.state.currentType].cricchetti.tipologia_cricchetti[0]==='Cinghia Scatto') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                cricchetti: {
                                    ...this.state.stepsData[this.state.currentType].cricchetti, 
                                    colore_cricchetti: ['', '']
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='tipologia_cinghie') {
            this.updateValidation(step, ValidateTipologiaCinghie(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='reflex') {
            this.updateValidation(step, ValidateReflex(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='maniglie') {
            this.updateValidation(step, ValidateManiglie(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='tipologia_carrelli') {
            let validation = ValidateTipologiaCarrelli(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validation)

            if (validation) {
                if (this.state.stepsData[this.state.currentType].tipologia_carrelli.tipologia_carrelli==='Recupero carrello esistente') {
                    let numeroCarrelli = ''
                    if (this.state.stepsData[this.state.currentType].teli_laterali.teli_laterali[0]==='Francese') {
                        numeroCarrelli = this.state.stepsData[this.state.currentType].cricchetti.numero_cricchetti
                    }

                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                tipologia_carrelli: {
                                    ...this.state.stepsData[this.state.currentType].tipologia_carrelli,
                                    numero_carrelli: numeroCarrelli
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='tipologia_tetto') {
            let validate = ValidateTipologiaTetto(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].tipologia_tetto.tipologia_tetto==='Tetto fisso') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                archi: this._initialStepsData()[this.state.currentType].archi,
                                cintiruni: this._initialStepsData()[this.state.currentType].cintiruni
                            }
                        }
                    })
                }
                else {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                traversi_correnti: this._initialStepsData()[this.state.currentType].traversi_correnti
                            }
                        }
                    })
                }
            }
        }
        else if (step==='colore_tetto') {
            let validate = ValidateColoreTetto(this.state.stepsData, this.state.currentStep, this.state.currentType)
            this.updateValidation(step, validate)

            if (validate) {
                if (this.state.stepsData[this.state.currentType].colore_tetto.colore_tetto!=='Fascia Colore') {
                    this.setState({
                        stepsData:
                        {
                            ...this.state.stepsData,
                            [this.state.currentType]: {
                                ...this.state.stepsData[this.state.currentType],
                                colore_tetto: {
                                    ...this.state.stepsData[this.state.currentType].colore_tetto,
                                    colore_tetto_colore: ''
                                }
                            }
                        }
                    })
                }
            }
        }
        else if (step==='traversi_correnti') {
            this.updateValidation(step, ValidateTraversiCorrenti(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='archi') {
            this.updateValidation(step, ValidateArchi(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='cinturini') {
            this.updateValidation(step, ValidateCinturini(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='fronte_tetto') {
            this.updateValidation(step, ValidateFronteTetto(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
        else if (step==='sgocciolatoio') {
            this.updateValidation(step, ValidateSgocciolatoio(this.state.stepsData, this.state.currentStep, this.state.currentType))
        }
    }

    updateValidation = (step, value, currentType = '') => {
        if (!currentType || currentType==='') {
            this.setState({
                stepsValidation:
                {
                    ...this.state.stepsValidation,
                    [step]: value
                }
            })
        }
        else {
            this.setState({
                currentType: currentType,
                stepsValidation:
                {
                    ...this.state.stepsValidation,
                    [step]: value
                }
            })
        }
    }


    //
    // Render
    //

    render() {
        const { history } = this.props
        const { path } = this.props.match

        const getHeader = () => {
            if (this.state.currentStep.id!=='riepilogo') {
                return (
                    <Header steps={this.state.steps} stepsData={this.state.stepsData} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsValidation={this.state.stepsValidation} />
                )
            }
            else {
                return null
            }
        }

        const getFooter = () => {
            if (this.state.currentStep.id!=='riepilogo') {
                return (
                    <div className="configurator__footer">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-6 col-lg-7 offset-1">
                                    <PrevButton currentStep={this.state.currentStep} currentType={this.state.currentType} updateStep={this.updateStep} />
                                </div>
                                <div className="col-16 col-lg-8 text-right text-lg-center">
                                    <NextButton steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} updateStep={this.updateStep} validateMisure={ValidateMisureMezzo} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="configurator__footer">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-6 col-lg-7 offset-1">
                                    <PrevButton currentStep={this.state.currentStep} currentType={this.state.currentType} updateStep={this.updateStep} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            
        }

        return (
            <div>
                <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                    if ((this.state.steps[this.state.currentStep.id] && this.state.steps[this.state.currentStep.id].position>1) || !this.state.steps[this.state.currentStep.id]) {
                        let valids = 0;

                        for (let item in this.state.stepsValidation) {
                            if (this.state.stepsValidation[item]) {
                                valids++;
                            }
                        }

                        // if (valids < this.state.steps[this.state.currentStep.id].position - 1) {
                        if (valids <= 0) {
                            history.push('/')
                        }
                    }
                }} />

                <div className="configurator">
                    {getHeader()}

                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/tipologia-mezzo`} />
                        </Route>
                        <Route path={`${path}/tipologia-mezzo`}>
                            <ShowStep stepId="tipologia-mezzo" steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/riepilogo`}>
                            <ShowStep stepId="riepilogo" steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/richiedi-preventivo`}>
                            <ShowStep stepId="richiedi-preventivo" steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                        <Route path={`${path}/:typeId/:stepId`}>
                            <ShowStep steps={this.state.steps} currentStep={this.state.currentStep} currentType={this.state.currentType} stepsData={this.state.stepsData} stepsValidation={this.state.stepsValidation} updateStep={this.updateStep} updateData={this.updateData} updateValidation={this.updateValidation} />
                        </Route>
                    </Switch>

                    {getFooter()}
                </div>
            </div>
        )
    }
}

const ShowStep = (props) => {
    let { typeId, stepId } = useParams();

    if (!stepId) {
        stepId = props.stepId
    }

    if (stepId === 'tipologia-mezzo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaMezzo steps={props.steps} currentStep={props.currentStep} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'misure-mezzo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <MisureMezzo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'teli') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Teli steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'teli-laterali') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TeliLaterali steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'colore-telo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <ColoreTelo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'logo') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Logo steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'elastico') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Elastico steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'cricchetti') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Cricchetti steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-cinghie') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaCinghie steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'reflex') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Reflex steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'maniglie') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Maniglie steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-carrelli') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaCarrelli steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'tipologia-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TipologiaTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'colore-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <ColoreTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'traversi-correnti') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <TraversiCorrenti steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'archi') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Archi steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'cinturini') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Cinturini steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'fronte-tetto') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <FronteTetto steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'sgocciolatoio') {
        return (
            <div className="configurator__content">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-24">
                            <Sgocciolatoio steps={props.steps} currentStep={props.currentStep} currentType={typeId} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} updateData={props.updateData} updateValidation={props.updateValidation} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else if (stepId === 'riepilogo') {
        return (
            <Riepilogo steps={props.steps} currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} />
        );
    }
    else if (stepId === 'richiedi-preventivo') {
        return (
            <RichiediPreventivo steps={props.steps} currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} stepsValidation={props.stepsValidation} updateStep={props.updateStep} />
        );
    }
    else {
        return (
            <Redirect to="/" />
        );
    }
}

export default withRouter(Configurator);
