import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';

class Cinturini extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['cinturini'];

        this.state = {
            nextStep: 'fronte_tetto'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__conturini">
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-6 offset-1 offset-lg-0">
                            <FormRadio name="file_cinturini" id="file_cinturini1" value="3 File" label="3 File" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cinturini.file_cinturini === '3 File'} />
                        </div>
                        <div className="col-22 col-lg-6 offset-1">
                            <FormRadio name="file_cinturini" id="file_cinturini2" value="4 File" label="4 File" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cinturini.file_cinturini === '4 File'} />
                        </div>
                        <div className="col-22 col-lg-6 offset-1">
                            <FormRadio name="file_cinturini" id="file_cinturini3" value="5 File" label="5 File" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].cinturini.file_cinturini === '5 File'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateCinturini = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].cinturini

    if(!currentStep.file_cinturini) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Cinturini)
