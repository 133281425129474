import React, { useState } from 'react';
import { withRouter } from "react-router-dom";

import Nav from './Nav.js'
import LogoPreview from './Logo/Confirm';

class Riepilogo extends React.Component {
    
    componentDidMount() {
        this.props.updateStep('riepilogo', 'Riepilogo', 'richiedi_preventivo')
    }

    render() {
        const { history } = this.props
        const { path } = this.props.match

        let newPath = path.replace('/riepilogo', '')

        const handleClick = () => {
            history.push(newPath + '/richiedi-preventivo');
        }

        return (
            <div className="riepilogo">
                <div className="container-fluid">
                    <div className="riepilogo__header riepilogo__header--desktop d-none d-lg-block">
                        <div className="row">
                            <div className="col-22 offset-1">
                                <svg className="svg-icon svg-icon--logo" viewBox="0 0 598 223" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref="#svg-icon__logo"></use>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="riepilogo__header riepilogo__header--mobile d-lg-none">
                        <div className="row">
                            <div className="col-22 offset-1">
                                <svg className="svg-icon svg-icon--logo-symbol-monocolor" viewBox="0 0 35 43" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref={"#svg-icon__logo-symbol-monocolor"}></use>
                                </svg>

                                <Navigation steps={this.props.steps} stepsData={this.props.stepsData} currentStep={this.props.currentStep} stepsValidation={this.props.stepsValidation} handleClick={handleClick} />
                            </div>
                        </div>
                    </div>

                    <div className="riepilogo__content">
                        <div className="row">
                            <div className="col-24 col-lg-12 col-xl-16">
                                <div className="ripilogo_preferenze">
                                    <div className="row">
                                        <div className="col-22 col-lg-20 col-xl-14 offset-1 offset-lg-2 offset-xl-6">
                                            <h1>Complimenti hai concluso la tua configurazione!</h1>
                                        </div>
                                        <div className="col-22 offset-1">
                                            <div className="d-none d-lg-block">
                                                <Preview stepsData={this.props.stepsData} />
                                            </div>
                                        </div>
                                        <div className="col-22 col-lg-20 col-xl-14 offset-1 offset-lg-2 offset-xl-6">
                                            <div className="d-none d-lg-block">
                                                <p>Ecco le tue preferenze riassunte:</p>
                                                <Nav steps={this.props.steps} stepsData={this.props.stepsData} currentStep={this.props.currentStep} currentType={this.props.currentType} stepsValidation={this.props.stepsValidation} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-22 offset-1">
                                <div className="d-lg-none">
                                    <Preview stepsData={this.props.stepsData} />
                                </div>
                            </div>
                            <div className="col-20 col-lg-8 offset-2 offset-lg-4">
                                <div className="ripilogo__preventivo">
                                    <h2>Richiedi subito un preventivo gratuito</h2>
                                    <p>Inserisci gli ultimi dati e sarai contattato da un nostro consulente via mail, o se preferisci telefonicamente, per fornirti la quotazione del tuo telone.</p>
                                    <button className="btn btn-secondary btn-lg" onClick={handleClick}>Richiedi ora</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

const Preview = (props) => {
    if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Semirimorchio') {
        return (
            <div className="riepilogo__preview">
                <div className="row no-gutters">
                    <div className="col-24 text-center">
                        <LogoPreview currentType={'semirimorchio'} stepsData={props.stepsData} getUploaded={true} />
                    </div>
                </div>
            </div>
        )
    }
    else if(props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice') {
        return (
            <div className="riepilogo__preview">
                <div className="row no-gutters">
                    <div className="col-24 text-center">
                        <LogoPreview currentType={'motrice'} stepsData={props.stepsData} getUploaded={true} />
                    </div>
                </div>
            </div>
        )
    }
    else if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
        return (
            <div className="riepilogo__preview">
                <div className="row no-gutters">
                    <div className="col-12 text-center">
                        <LogoPreview currentType={'motrice'} stepsData={props.stepsData} getUploaded={true} />
                    </div>
                    <div className="col-12 text-center">
                        <LogoPreview currentType={'rimorchio'} stepsData={props.stepsData} getUploaded={true} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

const Navigation = (props) => {
    const [show, setShow] = useState(false);

    const handleShow = () => {
        if(!show) {
            setShow(true)
        }
        else {
            setShow(false)
        }
    };

    return (
        <div className="riepilogo-navigation">
            <button className="riepilogo-navigation__toggle" onClick={handleShow}>
                <span>Riguarda preferenze</span>
                <svg className="svg-icon svg-icon--list" viewBox="0 0 23 15" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                    <use xlinkHref="#svg-icon__list"></use>
                </svg>
            </button>
            <div className={show ? "riepilogo-navigation__content _shown" : "riepilogo-navigation__content"} onClick={handleShow}>
                <div className="riepilogo-navigation__header">
                    <div className="row no-gutters">
                        <div className="col-22 offset-1">
                            <svg className="svg-icon svg-icon--logo-symbol" viewBox="0 0 35 43" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                <use xlinkHref={"#svg-icon__logo-symbol"}></use>
                            </svg>
                            <button className="riepilogo-navigation__toggle" onClick={handleShow}>
                                <span>Riguarda preferenze</span>
                                <svg className="svg-icon svg-icon--close" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                    <use xlinkHref="#svg-icon__close"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="riepilogo-navigation__body">
                    <div className="row no-gutters">
                        <div className="col-20 offset-2">
                            <span className="riepilogo-navigation__title">Ecco le tue preferenze riassunte:</span>
                            <Nav steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} />
                        </div>
                    </div>
                </div>
                <div className="riepilogo-navigation__footer">
                    <div className="row no-gutters">
                        <div className="col-22 offset-1">
                            <button className="btn btn-secondary btn-lg" onClick={props.handleClick}>Richiedi subito un preventivo gratuito</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Riepilogo);
