import React, { useRef, useEffect, useState } from 'react'

import Image from '../Image.js';


const Confirm = (props) => {
    const logoRef = useRef(null);
    const [logoWidth, setLogoWidth] = useState(0);

    useEffect(() => {
        if (logoRef.current && logoRef.current.offsetWidth>0) {
            setLogoWidth(logoRef.current.offsetWidth)
        }
    }, [setLogoWidth]);

    const logoWrapperStyle = () => {
        if (props.currentType==='motrice') {
            if (props.stepsData[props.currentType].teli_laterali.teli_laterali[0]==='Francese') {
                return { transform: 'perspective(' + logoWidth + 'px) rotateX(-7deg)' }
            }
            else {
                return { transform: 'perspective(' + logoWidth + 'px) rotateX(-7deg)' }
            }
        }
        else if (props.currentType==='semirimorchio') {
            if (props.stepsData[props.currentType].teli_laterali.teli_laterali[0]==='Francese') {
                return { transform: 'perspective(' + logoWidth + 'px) rotateX(-9deg)' }
            }
            else {
                return { transform: 'perspective(' + logoWidth + 'px) rotateX(-9deg)' }
            }
        }
        else if (props.currentType==='rimorchio') {
            if (props.stepsData[props.currentType].teli_laterali.teli_laterali[0]==='Francese') {
                return { transform: 'perspective(' + logoWidth + 'px) rotateX(-9deg)' }
            }
            else {
                return { transform: 'perspective(' + logoWidth + 'px) rotateX(-9deg)' }
            }
        }
        else {
            return { transform: 'perspective(' + logoWidth + 'px) rotateX(-17deg)' }
        }
    }

    // const roofColor = () => {
    //     if (props.getUploaded && props.stepsData[props.currentType].colore_tetto.colore_tetto) {
    //         if (props.stepsData[props.currentType].colore_tetto.colore_tetto==='Monocolore bianco') {
    //             return '/tetto-1'
    //         }
    //         else if(props.stepsData[props.currentType].colore_tetto.colore_tetto==='Bianco con fascia nera') {
    //             return '/tetto-2'
    //         }
    //         else {
    //             return ''
    //         } 
    //     }
    //     else {
    //         return ''
    //     }
    // }

    const imageDirectory = () => {
        if (props.stepsData[props.currentType].teli.teli!=='Telo tetto') {
            return 'mezzo/' + props.currentType + '/' + props.stepsData[props.currentType].teli_laterali.teli_laterali[0].toLowerCase()// + roofColor()
        }
        else {
            return 'mezzo/' + props.currentType
        }
    }

    const imageName = () => {
        if (props.stepsData[props.currentType].teli.teli!=='Telo tetto') {
            if (props.stepsData[props.currentType].elastico.elastico==='Esterno') {
                return 'mezzo_elastico'
            }
            else {
                return 'mezzo'
            }
        }
        else {
            return 'tetto'
        }
    }

    const imageColor = () => {
        if (props.stepsData[props.currentType].teli.teli!=='Telo tetto') {
            return props.stepsData[props.currentType].colore_telo.colore_telo[2]
        }
        else {
            if (props.stepsData[props.currentType].colore_tetto.colore_tetto==='Monocolore bianco') {
                return 1
            }
            else if(props.stepsData[props.currentType].colore_tetto.colore_tetto==='Bianco con fascia nera') {
                return 2
            }
            else {
                return ''
            }
        }
    }
    
    const wrapperClass = () => {
        if (props.stepsData[props.currentType].teli.teli!=='Telo tetto') {
            return props.currentType
        }
        else {
            return 'tetto'
        }
    }

    return (
        <div className={ "logo-confirm logo-confirm--" + wrapperClass() }>
            <div className={ "logo-confirm__logo logo-confirm__logo--" + props.currentType + '-' + props.stepsData[props.currentType].teli_laterali.teli_laterali[0].toLowerCase() } ref={logoRef} style={logoWrapperStyle()}>
                <Logo getUploaded={props.getUploaded} stepsData={props.stepsData} currentType={props.currentType}  />
            </div>
            <div className="logo-confirm__background">
                <Image directory={imageDirectory()} name={imageName()} currentType={props.stepsData.tipologia_mezzo.tipo_mezzo} changeColor={true} currentColor={imageColor()} />
            </div>
        </div>
    )
}

const Logo = (props) => {
    if (props.stepsData[props.currentType].logo.file.name_copy) {
        const logoStyle = {
            width: props.stepsData[props.currentType].logo.position_percent.width + "%",
            height: props.stepsData[props.currentType].logo.position_percent.height + "%",
            top: props.stepsData[props.currentType].logo.position_percent.y + "%",
            left: props.stepsData[props.currentType].logo.position_percent.x + "%"
        };

        return (
            <div>
                <img src={ !props.getUploaded ? props.stepsData[props.currentType].logo.file.preview : 'https://configuratore.telonitosetto.it/files/b2c/tmp/' + props.currentType + '_' + props.stepsData[props.currentType].logo.file.name_copy } alt="" style={logoStyle} />
            </div>
        )
    }
    else {
        return null
    }
}

export default Confirm;
